import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Alert } from "@material-ui/lab";

import { BigTitle, GoBack } from "../../../shared/utils/styled-components-library.utils";
import { ProfileInfoWrapper, ProfileTitle } from "./shared/styled-components.library";
import { get, post } from "../../../shared/utils/api.utils";

import ProfileCard from "./profiles/profile-card.component";
import ProfileCardSidebar from "./profiles/profile-card-sidebar.component";
import ProfileCardActions from "./profiles/profile-card-actions.component";
import ActionSidebar from "../profilecard/action-bar.component";
import ItemContainer from "./shared/item-container.component";
import ProfileInfo, { Tab, TabWrapper } from '../../../shared/components/profile-info.component';
import { FilterNeedsEnum } from "../../../pages/auth/personal-account.page";
import { useLocation } from "react-router-dom";
import DeleteProfileModal from "../../modals/delete-profile.modal";
import UpdateProfileModal from "../../modals/update-profile.modal";
import UpdateNeedModal from "../../modals/update-need.modal";
import ManageItemModal from "../../modals/manage-item.modal";
import MatchItemModal from "../../modals/match-item.modal";

export default function CompanyProfiles({ nameSpace, user, setUser, setShouldRefreshUser, fixedProfile, loadedFields, loadedWorkLocations, auth }) {
  const location = useLocation();
  const allItems = nameSpace === 'needs' ? user.needs : user.profiles;
  const [rowPerPage, setRowPerPage] = useState(8);

  // profil à afficher sur l'écran si l'utilisateur clique sur le bouton "Afficher"
  const [profile, setProfile] = useState(fixedProfile ?? null);
  const [shouldResetProfileView, setShouldResetProfileView] = useState(false);
  const [displayedProfile, setDisplayedProfile] = useState(null);

  const [hasNewProfile, setHasNewProfile] = useState(false);
  const [updatingProfile, setUpdatingProfile] = useState(false);

  // Permet d'avoir des tabs dynamiques en haut de page
  const [currentTab, setCurrentTab] = useState(FilterNeedsEnum.ACTIVE);

  const [showEditItemModal, setShowEditItemModal] = useState(false);
  const [profileToUpdate, setProfileToUpdate] = useState(null);
  const [showDeleteProfileModal, setShowDeleteProfileModal] = useState(false);
  const [profileToDelete, setProfileToDelete] = useState(null);
  const [showManageItemModal, setShowManageItemModal] = useState(false);
  const [itemToManage, setItemToManage] = useState(null);
  const [showMatchItemModal, setShowMatchItemModal] = useState(false);
  const [itemToMatch, setItemToMatch] = useState(null);

  useEffect(() => {
    if (fixedProfile) {
      setProfile(fixedProfile);
    }
  }, [fixedProfile]);

  // Permet de réinitialiser le profil affiché si on passe de l'onglet besoins
  // ouverts à pourvus et inversément
  useEffect(() => {
    setProfile(null);
    setDisplayedProfile(null);
  }, [currentTab]);

  useEffect(() => {
    if (shouldResetProfileView) {
      setProfile(null);
      setShouldResetProfileView(false);
    }
  }, [shouldResetProfileView, setShouldResetProfileView]);

  const handleTabChange = (newTab) => {
    if (newTab !== currentTab) {
      setCurrentTab(newTab);
    }
  }

  useEffect(() => {
    if (!user) return;
    const params = new URLSearchParams(location.search);
    // Affiche un profil ou un besoin particulier
    let itemHash = params.get("show");
    if (itemHash != null) {
      const item = user[nameSpace].find((item) => item.hash === itemHash);
      if (item) {
        setProfile(item);
      }
    } else
      setProfile(null);

    // Affiche le matching d'un profil ou d'un besoin particulier
    itemHash = params.get("matching");
    if (itemHash != null) {
      const item = user[nameSpace].find((item) => item.hash === itemHash);
      if (item) {
        setItemToMatch(item);
        setShowMatchItemModal(true);
      }
    } else
      setShowMatchItemModal(false);

    // Affiche le positinnement d'un profil ou les candidatures d'un besoin particulier
    itemHash = params.get("candidatures");
    if (itemHash != null) {
      const item = user[nameSpace].find((item) => item.hash === itemHash);
      if (item) {
        setItemToManage(item);
        setShowManageItemModal(true);
      }
    } else
      setShowManageItemModal(false)
  }, [location.search, user]);

  // Modal actions
  const manageItem = (item) => {
    setItemToManage(item);
    setShowManageItemModal(true);
  };

  const matchItem = (item) => {
    setItemToMatch(item);
    setShowMatchItemModal(true);
  };

  const deleteProfile = (profile) => {
    setProfileToDelete(profile);
    setShowDeleteProfileModal(true);
  };

  const updateProfile = (profile) => {
    setProfileToUpdate({ ...profile });
    setShowEditItemModal(true);
  };

  const refreshUserData = () => {
    get(`/users/${auth.userId}`).then((user) => {
      if (user) {
        setUser(user);
      }
    });
  };



  const nameSpaceSingular = nameSpace.slice(0, -1);

  useEffect(() => {
    if (profile ===  null) {
      if (user.profile !== 'freelance')
        setDisplayedProfile(null);
      return ;
    }
    get(`/${nameSpace}/${profile.hash}`).then(response => {
      if (response && response.success) {
        setDisplayedProfile(response.profile);
      }
      else
        setProfile(null);
    });
  }, [user, profile]); // eslint-disable-line

  useEffect(() => {
    let timeoutId = null;
    const newProfile = localStorage.getItem("new-${nameSpaceSingular}");
    if (newProfile === "true") {
      setHasNewProfile(true);
      localStorage.removeItem("new-${nameSpaceSingular}");
      timeoutId = setTimeout(() => setHasNewProfile(false), 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    }
  }, []);

  const updateProfileActivation = (profileToUpdate) => {
    if (!updatingProfile) {
      setUpdatingProfile(true);
      const payload = { newActivity: !profileToUpdate.isActive }
      payload[`${nameSpaceSingular}Id`] = profileToUpdate[`${nameSpaceSingular}Id`];
      post(`/${nameSpace}/update-${nameSpaceSingular}-activation`,payload ).then(response => {
        if (response && response.success) {
          let newUser = { ...user };
          newUser[`${nameSpace}`] = newUser[`${nameSpace}`].map(_profile => _profile.hash !== profileToUpdate.hash ? _profile : ({ ..._profile, isActive: !profileToUpdate.isActive }));

          setUser(newUser);
          if (profile) { // si "profile" n'est pas NULL, cela veut dire qu'on l'a désactivé depuis la fiche du profil
            setDisplayedProfile(_profile => ({ ..._profile, isActive: !_profile.isActive }));
          }
        }
        setShouldRefreshUser(true);
        setUpdatingProfile(false);
      });
    }
  }

  const renewProfile = (profileToUpdate) => {
    if (!updatingProfile) {
      setUpdatingProfile(true);
      const payload = {}
      payload[`${nameSpaceSingular}Id`] = profileToUpdate[`${nameSpaceSingular}Id`];
      post(`/${nameSpace}/renew`, payload).then(response => {
        if (response && response.success) {
          setUser(user => ({
            ...user,
            profiles: user.profiles.map(_profile => _profile.hash !== profileToUpdate.hash ? _profile : ({ ..._profile, updatedAt: response.updatedAt, isActive: true }))
          }));
          if (profile) {
            setDisplayedProfile(_profile => ({ ..._profile, updatedAt: response.updatedAt, isActive: true }));
          }
        }
        setUpdatingProfile(false);
      });
    }
  }

  let filteredProfiles = allItems.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  if (currentTab === FilterNeedsEnum.ACTIVE && nameSpace === 'needs') {
    filteredProfiles = allItems.filter(profile => !profile.won);
  } else if (nameSpace === 'needs') {
    filteredProfiles = allItems.filter(profile => profile.won);
  }



  const renderCard = (profile) => {
    const cardProps = { nameSpace, profile, user, setProfile, updateProfile, deleteProfile, updateProfileActivation, renewProfile };
    const sidebar = <ProfileCardSidebar {...cardProps} />;
    const bottom = 
      <ProfileCardActionButtons>
        <ProfileCardActions
          profile={profile}
          user={user}
          setProfile={setProfile}
          updateProfile={updateProfile}
          deleteProfile={deleteProfile}
          updateProfileActivation={updateProfileActivation}
          renewProfile={renewProfile}
          manageItem={manageItem}
          matchItem={matchItem}
          nameSpace={nameSpace}
          setShouldRefreshUser={setShouldRefreshUser}
        />
      </ProfileCardActionButtons>
    return (
      <ProfileCard key={profile.hash}
        {...cardProps}
        large
        sidebar={sidebar}
        bottom={bottom}
        isPrivate
        topClickable
      />
    );
  };

  const pageTitle = () => {
    if (user.profile !== 'freelance') {
      return `VOS ${ nameSpace !== 'needs' ? 'INTER-CONTRATS' : 'BESOINS' }`;
    } else {
      return 'MON PROFIL ET MES DISPOS';
    }
  }

  const CreateProfileButton = () => {
    if (user.profile !== 'freelance')
      return (
        <Link to={ `/creer-${nameSpace === 'needs' ?  'besoin' : 'interco'}` }>
          <AnimatedButton>Proposez un { nameSpace === 'needs' ?  'besoin' : 'interco' }</AnimatedButton>
        </Link>
      );
    else return (
        <Link to={ '/creer-fiche-profil' }>
          <AnimatedButton>Créez maintenant votre fiche profil</AnimatedButton>
        </Link>
    );
  }


  return (
    <div style={{ width: '100%' }}>
      { displayedProfile && user.profile !== 'freelance' ?
        <GoBackWrapper>
          <GoBack onClick={() => setProfile(null)}>
            <span>{"<"}</span>
            <span>Retour</span>
          </GoBack>
        </GoBackWrapper> : null
      }
      <BigTitle>{ pageTitle() }</BigTitle>
      { nameSpace === 'needs' &&
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <TabWrapper style={{ width: '30%', marginBottom: '50px' }}>
            <Tab active={currentTab === FilterNeedsEnum.ACTIVE} onClick={() => handleTabChange(FilterNeedsEnum.ACTIVE)}>Ouverts</Tab>
            <Tab
              active={currentTab === FilterNeedsEnum.WON}
              onClick={() => handleTabChange(FilterNeedsEnum.WON)}
              title={"La description n'a pas été renseignée"}
            >
              Pourvus
            </Tab>
          </TabWrapper>
        </div>
      }
      {
        filteredProfiles.length > 0 ?
          (displayedProfile ?
                 <ProfileInfoWrapper>
                   <ActionSidebar
                     nameSpace={nameSpace}
                     isPrivateSidebar
                     user={user}
                     profile={displayedProfile}
                     updateProfile={updateProfile}
                     updateProfileActivation={updateProfileActivation}
                     deleteProfile={deleteProfile}
                     renewProfile={renewProfile}
                     manageItem={manageItem}
                     setShouldRefreshUser={setShouldRefreshUser}
                   />
                   <div style={{overflow: 'auto'}}>
                     <ProfileTitle>
                       <span>{displayedProfile.title.toUpperCase()}</span>
                       {displayedProfile.anonymous ? <span className="anonymous">{nameSpace === "profiles"
                        ? "Profil "
                        : "Besoin "}anonymisé</span> : ""}
                     </ProfileTitle>
                     <ProfileInfo nameSpace={nameSpace} profile={displayedProfile} forPrivateProfile={true}/>
                   </div>
                 </ProfileInfoWrapper>
           : <BodyWrapper>
               {hasNewProfile && <div style={{ maxWidth: "400px" }}><Alert severity="success">Votre nouvel interco a bien été créé.</Alert></div>}
                 <ItemContainer
                   items={ filteredProfiles }
                   renderCard={ renderCard }
                   searchPlaceHolder={ `Nom ${ nameSpace === 'needs' ?  'du besoin' : 'de l\'interco'} / référence` }
                   filteredItems={ filteredProfiles }
                   rowPerPage={ rowPerPage }
                   setRowPerPage={ setRowPerPage }
                 />
             </BodyWrapper>
          )
        : <CreateProfileButton />
      }
      { itemToManage && <ManageItemModal
        nameSpace={nameSpace}
        showModal={showManageItemModal}
        closeModal={() => setShowManageItemModal(false)}
        item={itemToManage}
        user={user}
        setShouldRefreshUser={setShouldRefreshUser}
      /> }
      { itemToMatch && <MatchItemModal
        nameSpace={nameSpace}
        showModal={showMatchItemModal}
        closeModal={() => setShowMatchItemModal(false)}
        user={user}
        item={itemToMatch}
      /> }
      <DeleteProfileModal
        nameSpace={nameSpace}
        showModal={showDeleteProfileModal}
        closeModal={() => setShowDeleteProfileModal(false)}
        profile={profileToDelete}
        user={user}
        setUser={setUser}
        setShouldRefreshUser={setShouldRefreshUser}
      />
      { nameSpace === 'profiles'
        ? <UpdateProfileModal
            fields={loadedFields}
            showModal={showEditItemModal}
            closeModal={() => setShowEditItemModal(false)}
            user={user}
            profile={profileToUpdate}
            setProfileToUpdate={setProfileToUpdate}
            loadedFields={loadedFields}
            loadedWorkLocations={loadedWorkLocations}
            refreshUserData={refreshUserData}
          />
        : <UpdateNeedModal
            fields={loadedFields}
            showModal={showEditItemModal}
            closeModal={() => setShowEditItemModal(false)}
            user={user}
            need={profileToUpdate}
            setNeedToUpdate={setProfileToUpdate}
            loadedFields={loadedFields}
            loadedWorkLocations={loadedWorkLocations}
            refreshUserData={refreshUserData}
          />
      }
    </div>
  );
}

const BodyWrapper = styled.div`
  background-color: white;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.3);
  border-radius: 70px;
  padding: 1em 4em;
`;

const GoBackWrapper = styled.div`
  width: 60px;
  margin-top: -30px;
  cursor: pointer;
`;


const ProfileCardActionButtons = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
        row-gap: 8px;

	& > * {
          width: 48%;
	}
`;

export const AnimatedButton = styled.button`
	user-select: none;
	color: #241f47;
	padding: 12px 36px;
	border: none;
	border-radius: 10px;
	font-size: 22px;
	font-family: "Prompt";
	background-image: linear-gradient(to right, #f9cdd5 0%, #f29daa 100%);
	transition: all 0.2s ease-in;
	cursor: pointer;

	&:hover {
		box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
	}

	&:focus {
		outline: none;
	}
`;
