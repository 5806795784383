import React, { useState, useContext } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { Alert } from "@material-ui/lab";

import { AuthContext } from "../../contexts/auth.context";
import TextInput from "./inputs/text-input.component";
import Button from "./buttons/button.component";
import Logo from "./logo.component";
import { signIn, saveToken, loadTokenPayload } from "../utils/auth.utils";
import { post } from "../utils/api.utils";
import { isValid } from "../utils/input-validation.utils";
import { ButtonsContainer } from "../utils/styled-components-library.utils";
import { ReactComponent as Times } from "../../svg-assets/times.svg";
import { useHistory } from "react-router-dom";

Modal.setAppElement("#root");

export default function SignInModal(props) {
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [_, setAuthState] = useContext(AuthContext); // eslint-disable-line
  const [resetMode, setResetMode] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    type: null,
    message: null,
  });
  const [showErrors, setShowErrors] = useState(false);
  const history = useHistory();

  const signInHandler = () => {
    const emailInputed = email.trim();
    setAlert({ show: false, type: null, message: null });
    if (!!emailInputed && isValid("email", emailInputed) && !!password) {
      setShowErrors(false);
      signIn(emailInputed, password).then((res) => {
        if (res.statusCode && res.statusCode === 401) {
          setAlert({
            show: true,
            type: "error",
            message:
              res.message === "ACCOUNT_DEACTIVATED"
                ? "Votre compte n'est pas encore activé ou a été désactivé par votre administrateur, veuillez le contacter."
                : "L'authentification a échoué, l'adresse e-mail ou le mot de passe est incorrect.",
          });
        }
        if (res.success) {
          setEmail("");
          setPassword("");
          const payload = loadTokenPayload(res.jwt);
          if (payload) {
            const isLogged = payload && payload.profile && payload.type;
            setAuthState((state) => ({
              ...state,
              logged: isLogged,
              profile: payload.profile || null,
              type: payload.type || null,
              userId: payload.id || null,
              companyId: payload.companyId || null,
            }));
            const params = new URL(document.location).searchParams;
            if (params.has("url")) {
              const url = atob(params.get("url"));
              window.location.href = url;
            } else {
              history.push(props.redirect);
              closeModal();
            }
          }
        }
      });
    } else {
      setShowErrors(true);
    }
  };

  const pressKeyHandler = (event) => {
    if (event.keyCode === 13) {
      signInHandler();
    }
  };

  const sendResetPasswordMail = () => {
    setAlert({ show: false, type: null, message: null });
    const emailInputed = email.trim();
    if (!isValid("email", emailInputed)) {
      setShowErrors(true);
      setAlert({
        show: true,
        type: "error",
        message:
          "Veuillez entrer votre adresse e-mail pour réinitialiser votre mot de passe.",
      });
    } else {
      setShowErrors(false);
      post("/auth/reset-password-email", { email: emailInputed }).then(
        (response) => {
          if (response && response.success) {
            setAlert({
              show: true,
              type: "info",
              message:
                "Un e-mail vous a été envoyé, suivez les instructions pour mettre à jour votre mot de passe.",
            });
          } else {
            const errorMessage =
              (response.error && response.message) ||
              "Une erreur s'est produite lors de l'envoi de l'e-mail pour réinitialiser le mot de passe.";
            setAlert({ show: true, type: "error", message: errorMessage });
          }
        }
      );
    }
  };

  const closeModal = () => {
    setEmail("");
    setPassword("");
    setResetMode(false);
    setAlert({ show: false, type: null, message: null });
    setShowErrors(false);
    props.closeModal();
  };

  const isCreatingProfile = ["/creer-interco", "/creer-fiche-profil"].includes(
    location.pathname
  );
  return (
    <Modal
      isOpen={props.showModal}
      onRequestClose={closeModal}
      style={modalCustomStyles}
    >
      <ModalBody>
        <CloseButtonContainer>
          <CloseIcon onClick={closeModal} />
        </CloseButtonContainer>
        <LogoContainer>
          <Logo width="165px" />
        </LogoContainer>
        {isCreatingProfile && (
          <CustomTitle>
            Veuillez vous connecter pour continuer la création de votre profil
          </CustomTitle>
        )}
        <SignInForm>
          <TextInput
            label="Adresse e-mail"
            value={email}
            onChangeHandler={(e) => setEmail(e.target.value)}
            required
            error={showErrors && !email.trim() && !isValid("email", email)}
            errorText="Veuillez entrer une adresse e-mail valide."
          />
          {!resetMode ? (
            <>
              <TextInput
                label="Mot de passe"
                type="password"
                value={password}
                onChangeHandler={(e) => setPassword(e.target.value)}
                onKeyDown={pressKeyHandler}
                required
                error={showErrors && !password.trim()}
                errorText="Veuillez entrer votre mot de passe."
              />
              <ForgottenPasswordContainer>
                <ForgottenPasswordLink onClick={() => setResetMode(true)}>
                  Mot de passe oublié ?
                </ForgottenPasswordLink>
              </ForgottenPasswordContainer>
              <ButtonsContainer
                style={{ marginTop: "40px", marginBottom: "20px" }}
              >
                <Button onClick={signInHandler} invisible>
                  Se connecter
                </Button>
                <Link to="/nous-contacter">
                  <Button onClick={closeModal} outlineButton secondary>
                    S'inscrire
                  </Button>
                </Link>
              </ButtonsContainer>
            </>
          ) : (
            <>
              <ButtonsContainer
                style={{ marginTop: "40px", marginBottom: "20px" }}
              >
                <Button onClick={() => setResetMode(false)} outlineButton>
                  Annuler
                </Button>
                <Button onClick={sendResetPasswordMail}>Réinitialiser</Button>
              </ButtonsContainer>
            </>
          )}
          {alert.show && <Alert severity={alert.type}>{alert.message}</Alert>}
        </SignInForm>
      </ModalBody>
    </Modal>
  );
}

const modalCustomStyles = {
  content: {
    borderRadius: "50px",
    border: "none",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
    overflow: "hidden",
    zIndex: 101,
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: "linear-gradient(to right, #241f47bb 0%, #42387abb 100%)",
    zIndex: 100,
  },
};

const ModalBody = styled.div`
  display: block;
  max-width: 536px;
`;

const CustomTitle = styled.h3`
  text-align: center;
  font-family: "Prompt";
  font-size: 18px;
  color: #333;
  margin-top: 20px;
`;

const SignInForm = styled.div`
  margin: 20px 50px 40px;
`;

const CloseButtonContainer = styled.div`
  width: calc(100% - 50px);
  height: 20px;
  margin-top: 40px;
  justify-content: flex-end;
  display: flex;
`;

const CloseIcon = styled(Times)`
  cursor: pointer;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ForgottenPasswordContainer = styled.div`
  width: 100%;
  text-align: right;
`;

const ForgottenPasswordLink = styled.span`
  color: #596ac9;
  font-size: 16px;
  cursor: pointer;
  text-decoration: underline;
  font-family: "Prompt Light";
`;
