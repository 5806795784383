import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { AuthContext } from "../../contexts/auth.context";

import SaveSearchModal from "../../specific/modals/save-search.modal";

import {
  BigTitle,
  BigTooltip,
} from "../../shared/utils/styled-components-library.utils";
import { get } from "../../shared/utils/api.utils";
import { fetchAllWorkLocations } from "../../shared/utils/fetch.utils";
import { getLocationLabelsById } from "../../shared/utils/profiles.utils";

import ButtonWithImage from "../../shared/components/buttons/button-with-image.component";
import ProfileFilters from "../../shared/components/profile-filters.component";
import ListingTable from "../../specific/components/listinginterco/listing-table.component";
import Help from "../../shared/components/images/help.component";

import { ReactComponent as MagnifyingGlass } from "../../svg-assets/loupe.svg";
import FiltersSelected from "../../specific/components/listinginterco/filters-selected.component";

const nextMode = { 0: 1, 1: 2, 2: 0 }; // pas de tri --> tri croissant --> tri décroissant --> pas de tri --> etc.

export default function ListingItems({ openSignInModal, context, applyFilters, getItems }) {
  const [auth] = useContext(AuthContext);
  const {
    nameSpace,
    filters,
    loadFilters,
    removeFilter,
    initFieldItems,
    searchValue,
    setSearchValue,
    appliedSearch,
    setAppliedSearch,
  } = useContext(context);

  const [fetchLoaded, setFetchLoaded] = useState(false); // utile pour les configurations de filtres à sauvegarder
  const [pageLoaded, setPageLoaded] = useState(false); // utile pour les configurations de filtres à sauvegarder
  const [loadedTjm, setLoadedTjm] = useState(null);
  const [loadingProfiles, setLoadingProfiles] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [displayedProfiles, setDisplayedProfiles] = useState([]);
  const [workLocations, setWorkLocations] = useState([]);
  const [fields, setFields] = useState([]);
  const [showSaveSearchModal, setShowSaveSearchModal] = useState(false);
  /**
   * "mode"
   * - 0 : Pas de tri
   * - 1 : Tri croissant
   * - 2 : Tri décroissant
   */
  const [sort, setSort] = useState({ field: "updated", mode: 2 });

  useEffect(() => {
    fetchAllFieldsByCategory();
    fetchAllWorkLocations((_locations) => setWorkLocations(_locations));
    let search = null;
    const storageSearch = localStorage.getItem(`filters-${nameSpace}`);
    if (storageSearch) {
      search = JSON.parse(storageSearch).search;
    }
    fetchProfiles(search);
  }, []); // eslint-disable-line

  /* ***** DEBUT CHARGEMENT CONFIGURATION *****  */
  useEffect(() => {
    // on est obligé d'attendre le chargement complet des données nécessaires aux filtres
    // avant de pouvoir initialiser le chargement d'une éventuelle configuration
    if (workLocations.length && fields.length) {
      setFetchLoaded(true);
    }
  }, [workLocations, fields]);

  // quand la page est initialisée, alors on charge la configuration des filtres de l'utilisateur
  useEffect(() => {
    if (fetchLoaded) {
      const savedFilters = localStorage.getItem(`${nameSpace}-filters`);
      if (savedFilters) {
        try {
          const parsedFilters = JSON.parse(savedFilters);
          loadFilters(parsedFilters);
          setLoadedTjm(parsedFilters.tjm);
          if (parsedFilters.search) {
            searchHandler(parsedFilters.search);
            setSearchValue(parsedFilters.search);
          }
        } catch {}
      }
      setPageLoaded(true);
    }
  }, [fetchLoaded]); // eslint-disable-line
  /*  ***** FIN CHARGEMENT CONFIGURATION *****  */

  /* useEffect très important, s'occupe du filtrage, du tri (et de la pagination) */
  useEffect(() => {
    // 1. on applique les filtres, s'il y en a
    const profilesToDisplay = applyFilters(profiles, filters); // !! NOTE : on pourrait essayer d'intégrer useMemo() pour éviter d'avoir à calculer ça lorsque l'on souhaite trier
    /*history.push({
				pathname: "/bourse-interco",
				search: "?page=1&count=10"
			});*/

    // 2. on applique le tri, s'il y en a
    profilesToDisplay.sort((a, b) => {
      if (!sort.field) {
        return 0;
      } else {
        return applySortHandler(a, b);
      }
    });
    setDisplayedProfiles(profilesToDisplay);
  }, [profiles, sort, filters]); // eslint-disable-line

  /**
   * Fonction permettant de récupérer les profils à afficher sur la bourse d'interco.
   * @param {object} params Les params ElasticSearch à appliquer dans la requête.
   */
  const fetchProfiles = (params) => {
    setLoadingProfiles(true);
    getItems(params).then((response) => {
      if (response) {
        setProfiles(response);
      }
      setLoadingProfiles(false);
    });
  };

  /**
   * Fonction permettant de récupérer tous les secteurs d'activités triés par categories
   */
  const fetchAllFieldsByCategory = () => {
    get("/fields/categories").then((fields) => {
      if (fields) {
        let formattedFields = [];
        for (const field of fields) {
          if (!formattedFields.includes(field.categoryLabel))
            formattedFields.push(field.categoryLabel);
          formattedFields.push(field);
        }
        setFields(formattedFields);
        initFieldItems(formattedFields);
      }
    });
  };

  /**
   * Permet de sélectionner un tri via le header du tableau des intercos.
   * @param {string} fieldName
   */
  const changeSort = (fieldName) => {
    if (sort.field === fieldName) {
      setSort((_sort) => ({ ..._sort, mode: nextMode[_sort.mode] }));
    } else {
      setSort({ field: fieldName, mode: 1 });
    }
  };

  /**
   * Fonction permettant d'appliquer le tri à effectuer en fonction du champ à trier.
   */
  const applySortHandler = (profileA, profileB) => {
    if (sort.mode === 0) {
      return 0;
    } else {
      let returnedValue = 0;
      switch (sort.field) {
        case "title":
          // returnedValue = profileA.title < profileB.title ? -1 : 1;
          returnedValue = profileA.title.localeCompare(profileB.title);
          break;
        case "experience":
          returnedValue =
            profileA.experienceYears < profileB.experienceYears ? -1 : 1;
          break;
        case "tjm":
          returnedValue = profileA.price < profileB.price ? -1 : 1;
          break;
        case "mobility":
          const labelA = getLocationLabelsById(
            workLocations,
            profileA.workLocations.slice(0, 2)
          ).join(", ");
          const labelB = getLocationLabelsById(
            workLocations,
            profileB.workLocations.slice(0, 2)
          ).join(", ");
          returnedValue = labelA < labelB ? -1 : 1;
          break;
        case "availability":
          returnedValue =
            profileA.availability < profileB.availability ? -1 : 1;
          break;
        case "updated":
          returnedValue =
            new Date(profileA.updatedAt) < new Date(profileB.updatedAt)
              ? -1
              : 1;
          break;
        default:
          break;
      }
      // par défaut, on trie de manière croissante, et on vérifie à la fin si le tri est décroissant
      // si c'est le cas, on envoie l'inverse de ce qu'on enverrai pour un tri croissant
      return sort.mode === 2 ? returnedValue * -1 : returnedValue;
    }
  };

  const searchHandler = (searchValue) => {
    if (!!searchValue.trim()) {
      setSort({ field: null, mode: 0 });
      setAppliedSearch(searchValue);
    } else {
      setAppliedSearch("");
    }
    fetchProfiles(searchValue);
  };

  const removeSearchFilter = () => {
    setSearchValue("");
    setAppliedSearch("");
    fetchProfiles("");
  };

  const keyPressHandler = (event) => {
    if (event.keyCode === 13) {
      searchHandler(searchValue);
    }
  };

  const saveSearchHandler = () => {
    if (auth.logged) {
      setShowSaveSearchModal(true);
    } else {
      openSignInModal();
    }
  };

  return (
    <PageContainer>
      <SidebarWrapper>
        {auth && (
          <SaveSearchButtonWrapper>
            <ButtonWithImage
              src={ "/assets/icons/save.png" }
              secondary
              gradient
              style={{ color: "white" }}
              onClick={saveSearchHandler}
            >
              Sauvegarder la recherche
            </ButtonWithImage>
          </SaveSearchButtonWrapper>
        )}
        <ProfileFilters
          nameSpace={nameSpace}
          fields={fields}
          workLocations={workLocations}
          showCheckedNumber
          saveConfig
          pageLoaded={pageLoaded}
          searchValue={searchValue}
          loadedTjm={loadedTjm}
          setLoadedTjm={setLoadedTjm}
          context={context}
        />
      </SidebarWrapper>
      <MainContentWrapper>
        <BigTitle>{ nameSpace === 'needs' ? 'LISTE DES BESOINS' : 'TOUS LES INTERCOS DISPONIBLES'}</BigTitle>
        <ContentCard>
          <SearchWrapper>
            <BigTooltip placement="right" title={SearchTooltipContent}>
              <SearchHelpWrapper>
                <Help>
                  <span>Aide</span>
                </Help>
              </SearchHelpWrapper>
            </BigTooltip>
            <SearchInputWrapper>
              <NakedInput
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Mots clés"
                onKeyDown={keyPressHandler}
              />
              <SearchButton onClick={() => searchHandler(searchValue)}>
                <MagnifyingGlass />
                <span>Rechercher</span>
              </SearchButton>
            </SearchInputWrapper>
          </SearchWrapper>
          {/* Comporte les filtres sélectionnés & le nombre de profiles trouvés */}
          <FiltersSelected
            filters={filters}
            removeFilterHandler={removeFilter}
            appliedSearch={appliedSearch}
            removeSearchFilter={removeSearchFilter}
            workLocations={workLocations}
            profilesFound={displayedProfiles.length}
          />
          {loadingProfiles ? (
            <LoadingWrapper>
              <CircularProgress color="secondary" />
            </LoadingWrapper>
          ) : (
            <>
              {displayedProfiles.length ? (
                <IntercoListWrapper>
                  <ListingTable
                    nameSpace={nameSpace}
                    profiles={displayedProfiles}
                    sortBy={changeSort}
                    workLocations={workLocations}
                    currentSort={sort}
                    openSignInModal={openSignInModal}
                  />
                </IntercoListWrapper>
              ) : (
                <Alert severity="info">
                  Aucun {nameSpace === 'profiles' ? 'profil' : 'besoin'} trouvé pour les filtres appliqués.
                </Alert>
              )}
            </>
          )}
        </ContentCard>
      </MainContentWrapper>
      <SaveSearchModal
        showModal={showSaveSearchModal}
        closeModal={() => setShowSaveSearchModal(false)}
        mode="save"
        userId={auth.userId}
        searchValue={searchValue}
        context={context}
        loadedFields={fields}
      />
    </PageContainer>
  );
}

const StyledSearchTooltip = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 600px;

  & > * {
    width: 100%;
    margin: 5px 0px;
    font-size: 11px;
  }

  & .red {
    color: #ff1731;
  }

  & .bold {
    font-weight: bold;
  }
`;

const SearchTooltipContent = (
  <StyledSearchTooltip>
    <span className="bold">Type de requêtes possibles</span>
    <table>
      <tr>
        <td>
          Opérateur <span className="red">ET</span>
        </td>
        <td />
      </tr>
      <tr>
        <td>
          Exemple : mot1 <span className="red">ET</span> mot2
        </td>
        <td>
          Le résultat <span className="bold">doit</span> contenir le mot1{" "}
          <span className="bold">ET</span> le mot2
        </td>
      </tr>
      <tr>
        <td>
          Opérateur <span className="red">ESPACE</span>
        </td>
        <td />
      </tr>
      <tr>
        <td>Exemple : mot1 mot2</td>
        <td>
          Même chose que le <span className="bold">ET</span>, un espace est
          équivalent à un ET
        </td>
      </tr>
      <tr>
        <td>
          Opérateur <span className="red">OU</span>
        </td>
        <td />
      </tr>
      <tr>
        <td>Exemple : mot1 mot2</td>
        <td>
          Le résultat <span className="bold">doit</span> contenir le mot1{" "}
          <span className="bold">OU</span> le mot2
        </td>
      </tr>
      <tr>
        <td>
          Opérateur <span className="red">« »</span> (guillemets)
        </td>
        <td />
      </tr>
      <tr>
        <td>
          Exemple : <span className="red">«</span> mot1 mot2{" "}
          <span className="red">»</span>
        </td>
        <td>
          Le résultat <span className="bold">doit</span> contenir le mot1 et le
          mot2 <span className="bold">à la lettre près</span>
        </td>
      </tr>
      <tr>
        <td>
          Opérateur <span className="red">*</span>
        </td>
        <td />
      </tr>
      <tr>
        <td>
          Exemple : mot<span className="red">*</span>
        </td>
        <td>
          Le résultat <span className="bold">doit</span> contenir un mot{" "}
          <span className="bold">commençant par « mot »</span>
        </td>
      </tr>
      <tr>
        <td>
          Opérateur <span className="red">()</span>
        </td>
        <td />
      </tr>
      <tr>
        <td>
          Exemple : « mot1 » ET <span className="red">(</span>« mot2 » OU « mot3
          »<span className="red">)</span>
        </td>
        <td>
          Le résultat <span className="bold">doit</span> contenir{" "}
          <span className="bold">le mot1 avec soit le mot2 ou le mot3</span>
        </td>
      </tr>
    </table>
  </StyledSearchTooltip>
);

const PageContainer = styled.div`
  padding-top: 40px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  background-image: url("/assets/images/plantes-roses.svg");
  background-repeat: no-repeat;
  background-position: 105% 100%;
  background-size: calc(15em + 5vw);
`;

const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 250px;
  width: 18%;
  margin: 0px 4% 0px auto;
  box-sizing: border-box;
`;

const SaveSearchButtonWrapper = styled.div`
  margin-bottom: 24px;
`;

const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 68%;
  margin-right: 4%;

  & > * {
    margin-bottom: 20px;
  }
`;

const ContentCard = styled.div`
  background-color: white;
  border-radius: 70px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  padding: 4em;
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SearchHelpWrapper = styled.div`
  width: 35px;
  margin-bottom: 20px;

  & span {
    width: 60px;
    color: #42387a;
    font-size: 12px;
    font-family: "Prompt Light";
    cursor: help;
  }
`;

const SearchInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: centers;
  border: 1px solid #42387a;
  border-radius: 25px;

  & > input {
    width: 85%;
  }
`;

const NakedInput = styled.input`
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  outline: none;
  font-size: 18px;
  font-family: "Prompt Light";
  color: #42387a;
  padding: 4px 4px 4px 12px;
`;

const SearchButton = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 3vw;
  font-size: 14px;
  font-family: "Prompt Light";
  border-radius: 25px;
  cursor: pointer;
  background-image: linear-gradient(to right, #f9cdd5 0%, #f29daa 100%);
  color: white;
  transition: all 0.3s ease;

  & span {
    margin-left: 10px;
  }

  & svg > g > g {
    fill: white;
  }
`;

const IntercoListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LoadingWrapper = styled.div`
  margin-top: 50px;
  text-align: center;
`;
