import { post } from "./api.utils";
import config from "../../config";

const tokenMiddleware = (response) => {
  return response.json().then((json) => {
    if (response.ok) {
      saveToken(json.jwt);
    }
    else {
      deleteToken();
    } 
    return json;
  });
}

export const signIn = async (email, password) => {
  return fetch(config.apiUrl + '/auth/signin', {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      password
    })
  }).then((response) => {
    return tokenMiddleware(response);
  });
};

export const signUp = async (body) => {
	return await post("/auth/signup", body);
}

export const getToken = () => {
	const token = window.localStorage.getItem("token");
	return token || null;
}

export const saveToken = (token) => {
	window.localStorage.setItem("token", token);
}

export const deleteToken = () => {
	window.localStorage.removeItem("token");
	window.localStorage.removeItem("needs-filters");
	window.localStorage.removeItem("profiles-filters");
}

export const loadTokenPayload = (token) => {
	if (!token) {
		return null;
	}
	const payload = token.split(".")[1];
	if (!payload) {
		return null;
	}
	try {
		const decoded = JSON.parse(atob(payload));
		return decoded;
	} catch {
		return null;
	}
}