import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { TextField, FormControl, withStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { dayOptions } from "../../constants/select-options.constants";
import ModalTemplate from "../../shared/components/modal-template.component";
import Button from "../../shared/components/buttons/button.component";
import UnderlineInput from "../../shared/components/inputs/big-underline-text-input.component";
import Image from "../../shared/components/images/image.component";
import Select from "../../shared/components/inputs/select-input.component";
import Switch from "../../shared/components/inputs/switch-input.component";
import ProfileFilters from "../../shared/components/profile-filters.component";
import { post } from "../../shared/utils/api.utils";

/**
 * @param {object} props
 * @param {'create' | 'edit' | 'save'} props.mode Le mode à activer pour le modal : "create" lors de la création
 * d'une nouvelle alerte, "edit" lors de la modification d'une alerte, et "save" lors de la sauvegarde d'une nouvelle
 * recherche via les filtres de la bourse d'interco.
 */
export default function SaveSearchModal(props) {
  const [pageInitiated, setPageInitiated] = useState(false);
  const { initFieldItems, filterItems } = useContext(props.context);
	const { nameSpace, filters, setFilters, resetFilters, refreshItems } = useContext(
		props.context
	);
	const {
		search,
		userId,
		mode,
		loadedWorkLocations,
    loadedFields,
		refreshUserAlerts,
		searchValue,
		...modalProps
	} = props;

	const [alert, setAlert] = useState({
		show: false,
		type: null,
		message: null
	});
	const [title, setTitle] = useState("");
	const [activateAlert, setActivateAlert] = useState(false);
	const [frequency, setFrequency] = useState(null);
	const [day, setDay] = useState(null);
	const [showErrors, setShowErrors] = useState(false);
	const [keywords, setKeywords] = useState("");

  useEffect(() => {
    if (!pageInitiated && !!loadedFields.length && !filterItems.fields.length) {
      initFieldItems(loadedFields);
      setPageInitiated(true);
    }
  }, [
    loadedFields,
    filterItems,
    initFieldItems,
    pageInitiated,
    setPageInitiated,
  ]);


	useEffect(
		() => {
			if (!modalProps.showModal) {
				setAlert({ show: false, type: null, message: null });
				setShowErrors(false);
				setTitle("");
				setFrequency(null);
				setDay(null);
				setActivateAlert(false);
			}
		},
		[modalProps.showModal]
	);

	useEffect(
		() => {
			if (mode === "create" || mode === "save") {
				if (mode === "create") {
					resetFilters();
				}
				setTitle("");
				setKeywords("");
				setFrequency(null);
				setDay(null);
				setActivateAlert(false);
			} else if (mode === "edit") {
				if (search) {
					const payload = JSON.parse(search.payload);
					const searchKeywords = payload.search;
					setFilters(payload);
					setTitle(search.title);
					setKeywords(searchKeywords);
					if (search.frequency) {
						setActivateAlert(true);
						setFrequency(search.frequency);
						setDay(search.day);
					} else {
						setActivateAlert(false);
					}
				}
			}
		},
		[mode, search] // eslint-disable-line
	);

	useEffect(
		() => {
			refreshItems();
		},
		[search, refreshItems]
	);

	useEffect(
		() => {
			if (activateAlert && mode !== "edit") {
				setFrequency("real-time");
			}
		},
		[activateAlert, mode]
	);

	const canSubmitSearch = () => {
		if (!title.trim()) {
			setAlert({
				show: true,
				type: "error",
				message: "Veuillez indiquer un titre pour sauvegarder votre recherche."
			});
			setShowErrors(true);
			return false;
		}
		return true;
	};

	const submitSearch = () => {
		if (!canSubmitSearch()) return null;
		setShowErrors(false);
		setAlert({ show: false, type: null, message: null });

		// !! TODO : ceci peut sûrement être amélioré pour éviter de la duplication de code
		if (mode === "create" || mode === "save") {
			post(`/users/${userId}/search`, {
				title,
				frequency: activateAlert ? frequency : null,
				day: frequency === "weekly" ? day : null,
				payload: JSON.stringify({
					...filters,
					search: keywords.trim() || searchValue || ""
				}),
        nameSpace,
			}).then(response => {
				if (response && response.success) {
					setAlert({
						show: true,
						type: "success",
						message: "Votre recherche a été sauvegardée !"
					});
					if (mode === "create") {
						refreshUserAlerts();
					}
				} else {
					setAlert({
						show: true,
						type: "error",
						message:
							"Une erreur s'est produite lors de la sauvegarde de votre recherche, veuillez réessayer ultérieurement."
					});
				}
			});
		} else if (mode === "edit") {
			post(`/users/${userId}/search/${search.userSearchId}`, {
				title,
				frequency: activateAlert ? frequency : null,
				day: frequency === "weekly" ? day : null,
				payload: JSON.stringify({ ...filters, search: keywords.trim() || "" })
			}).then(response => {
				if (response && response.success) {
					setAlert({
						show: true,
						type: "success",
						message: "Votre recherche a bien été modifiée !"
					});
					refreshUserAlerts();
				} else {
					setAlert({
						show: true,
						type: "error",
						message:
							"Une erreur s'est produite lors de la modification de votre recherche, veuillez réessayer ultérieurement."
					});
				}
			});
		}
	};

	return (
		<ModalTemplate {...modalProps} width="1000px">
			<MainWrapper>
				<FormWrapper>
					<div>
						<UnderlineInput
							value={title}
							onChangeHandler={e => setTitle(e.target.value)}
							placeholder="Titre de la recherche"
							error={showErrors && !title.trim()}
							errorText="Ce champ est obligatoire."
						/>
					</div>
					{mode !== "save" ? (
						<>
							<KeywordsWrapper>
								<FormControl fullWidth>
									<CustomTextField
										label="Mots clés"
										variant="outlined"
										value={keywords}
										onChange={e => setKeywords(e.target.value)}
									/>
								</FormControl>
							</KeywordsWrapper>
							<FiltersWrapper>
								<ProfileFilters
									nameSpace={nameSpace}
									workLocations={loadedWorkLocations}
									showCheckedNumber
									context={ props.context }
								/>
							</FiltersWrapper>
						</>
					) : null}
					<SettingsWrapper>
						<div className="toggleAlert">
							<span>Activer les alertes par mail</span>
							<Switch
								checked={activateAlert}
								onChangeHandler={e => setActivateAlert(e.target.checked)}
							/>
						</div>
						{activateAlert && (
							<>
								<div className="frequency">
									<FrequencyButton
										active={frequency === "real-time"}
										onClick={() => setFrequency("real-time")}
									>
										Temps réel
									</FrequencyButton>
									<FrequencyButton
										active={frequency === "daily"}
										onClick={() => setFrequency("daily")}
									>
										Quotidienne
									</FrequencyButton>
									<FrequencyButton
										active={frequency === "weekly"}
										onClick={() => setFrequency("weekly")}
									>
										Hebdomadaire
									</FrequencyButton>
								</div>
								{frequency === "daily" && (
									<span className="frequency-info">Un mail vous sera envoyé tous les matins à 6h</span>
								)}

								{frequency === "weekly" && (
									<div>
										<Select
											options={dayOptions}
											value={day}
											onChangeHandler={e => setDay(e.target.value)}
											label="Quel jour ?"
										/>
									</div>
								)}
							</>
						)}
					</SettingsWrapper>
					{alert.show && (
						<AlertWrapper>
							<Alert severity={alert.type}>{alert.message}</Alert>
						</AlertWrapper>
					)}
				</FormWrapper>
				<ImageWrapper>
					<Image src="/assets/images/gorille-clock.png" width="100%" />
					<Tip>La clef du biz, c'est la réactivité</Tip>
				</ImageWrapper>
			</MainWrapper>
			<ButtonsWrapper>
				<Button secondary uppercase style={{ paddingBottom: 15, paddingTop: 15}} onClick={modalProps.closeModal}>Annuler</Button>
				<Button uppercase onClick={submitSearch} style={{ paddingBottom: 15, paddingTop: 15}}>Enregistrer</Button>
			</ButtonsWrapper>
		</ModalTemplate>
	);
}

const MainWrapper = styled.div`
	display: flex;
	flex-direction: row;
	overflow-y: auto;
	max-height: 60vh;
	margin-right: -20px;
`;

const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 40%;

	& > * {
		margin-left: 50px;
	}
`;

const KeywordsWrapper = styled.div`
	margin-top: 10px;
`;

const FiltersWrapper = styled.div`
	margin: 25px 0 25px 50px;
`;

const SettingsWrapper = styled.div`
	font-family: "Prompt";
	font-size: 16px;

	& .toggleAlert {
		display: flex;

		& > span {
			margin-right: 10px;
			color: #241f47;
		}
	}

	& .frequency {
		display: flex;
		width: 130%;
		& > * {
			width: 30%;
		}
	}

	& .frequency-info {
		display: block;
		width: 130%;
		font-size: 14px;
		color: #817cc4;
	}

	& > * {
		margin: 10px 0;
	}
`;

const FrequencyButton = styled.button`
	padding: 6px 0;
	margin-right: 8px;
	color: ${props => (props.active ? "#FFF" : "#241f47")};
	background-color: ${props => (props.active ? "#241f47" : "transparent")};
	border: ${props => (props.active ? "1px solid transparent" : "1px solid #f2a0ad")};
	border-radius: 20px;
	outline: none;
	transition: all 300ms ease;
	cursor: pointer;

	&:hover {
		background-color: ${props => (props.active ? "#241f47" : "transparent")};
	}
`;

const AlertWrapper = styled.div`
	margin: 10px 0;
`;

const ButtonsWrapper = styled.div`
	display: flex;
	margin-top: 15px;
	margin-bottom: 15px;
	justify-content: center;
	
	& > * {
		margin-right: 8px;
	}
`;

const ImageWrapper = styled.div`
	width: 420px;
	margin-left: auto;
	margin-right: auto;
`;

const Tip = styled.div`
	font-family: "Prompt Semi Bold";
	color: #f093a9;
	text-align: center;
	font-size: 18px;
	margin-top: 2em;
`;

const CustomTextField = withStyles({
	root: {
		width: '100%',
		'& label': {
			fontFamily: 'Prompt Light',
			color: '#42387a',
			left: '13px',
		},
		'& label.Mui-focused, & label.Mui-disabled': {
			color: '#42387a',
		},
		'& .MuiInputLabel-asterisk': {
			color: '#f2a0ad',
		},
		'& .MuiOutlinedInput-root': {
			fontFamily: 'Prompt Light',
			color: '#42387a',
			'& input': {
				padding: "18.5px 27px !important",
				zIndex: 1,
			},
			'& textarea': {
				zIndex: 1,
			},
			'& fieldset': {
				backgroundColor: 'white',
				borderColor: '#ffc4ce',
				width: '100%',
				boxSizing: 'border-box',
				borderRadius: '15px',
				borderWidth: '1px',
				padding: '0 20px',
			},
			'&:hover fieldset': {
				borderColor: '#f2a0ad',
			},
			'&.Mui-focused fieldset': {
				borderColor: '#f2a0ad',
			},
			'&.Mui-disabled fieldset': {
				fontWeight: 'bold',
				borderColor: '#ffc4ce',
			}
		},

	},
})(TextField);