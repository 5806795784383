import React, { useState, useEffect, useContext, useCallback } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import DeleteUserModal from "../../specific/modals/delete-user.modal";
import { AuthContext } from "../../contexts/auth.context";
import { Sidebar } from "../../specific/components/personal-account/sidebar.component";
import { get } from "../../shared/utils/api.utils";
import {
  fetchAllWorkLocations,
  fetchAllFieldsByCategories,
} from "../../shared/utils/fetch.utils";
import FreelanceProfiles from "../../specific/components/personal-account/freelance-profile.component";
import CompanyProfiles from "../../specific/components/personal-account/company-profiles.component";
import Contact from "../../specific/components/personal-account/contact.component";
import ManageUsers from "../../specific/components/personal-account/manage-users.component";
import Settings from "../../specific/components/personal-account/settings.component";
import SearchAlerts from "../../specific/components/personal-account/search-alerts.component";
import CompanyList from "../../specific/components/personal-account/company-lists.component";
import Messages from "../../specific/components/personal-account/messages.component";
import Statistics from "../../specific/components/personal-account/statistics.component";
import Payment from "../../specific/components/personal-account/payment.component";
import Referral from "../../specific/components/personal-account/referral.component";
import {FiltersProvider, FiltersContext} from '../../contexts/filters.context';
import { AbstractChoice } from "../../shared/components/layout/abstract-choice.component";
import config from '../../../src/config';
import { CircularProgress } from "@material-ui/core";

export const FilterNeedsEnum = Object.freeze({
  ACTIVE: 0,
  WON: 1,
});

export const Search = (props) => {
  return (
    <FiltersProvider>
      <SearchAlerts context={FiltersContext} {...props} />
    </FiltersProvider>
  );
}

// nameSpace enables to use the same components for a profile and for needs
const tabsMap = new Map();
tabsMap.set("Mes inter-contrats", {
  attachments: {
    queryParam: 'profil', 
    nameSpace: 'profiles'
  },
  component() {
    return CompanyProfiles;
  },
  validationFunction({ user }) {
    if (user.profile === "end-client" || user.profile === "freelance")
      return false;
    return true;
  },
  additionalProps: {
    nameSpace: 'profiles'
  },
  // key is used because otherwise, React treats CompanyProfiles as the same
  // component as in "Mes besoins"
  key: "profiles",
});
tabsMap.set("Gérer mon profil", {
  attachments: {
    queryParam: 'profil', 
    nameSpace: 'profiles'
  },
  component() {
    return FreelanceProfiles;
  },
  validationFunction({ user }) {
    if (user.profile !== "freelance")
      return false;
    return true;
  },
  additionalProps: {
    nameSpace: 'profiles'
  },
  // key is used because otherwise, React treats CompanyProfiles as the same
  // component as in "Mes besoins"
  key: "profiles",
});
tabsMap.set("Mes besoins", {
  attachments: {
    queryParam: 'besoins', 
    nameSpace: 'needs'
  },
  component: () => CompanyProfiles,
  validationFunction({ user }) {
    if (user.profile === "freelance")
      return false;
    return true;
  },
  additionalProps: {
    nameSpace: 'needs'
  },
  key: "needs",
});
tabsMap.set("Mes recherches et alertes", {
  attachments: {
    queryParam: 'recherche',
  },
  component: () => Search,
});
tabsMap.set("Référencements", {
  attachments: {
    queryParam: 'referencements',
  },
  component: () => CompanyList,
  validationFunction({ user }) {
    if (user.profile === "freelance")
      return false;
    return true;
  },
});
tabsMap.set("Messages", {
  attachments: {
    queryParam: 'messages',
  },
  component: () => Messages,
});
tabsMap.set("Statistiques", {
  attachments: {
    queryParam: 'statistiques',
  },
  component: () => Statistics,
});
tabsMap.set("Parrainer", {
  attachments: {
    queryParam: 'parrainage',
  },
  component: () => Referral
});
tabsMap.set("Contacter l'équipe d'1terco", {
  attachments: {
    queryParam: 'contact', 
  },
  component: () => Contact
});
tabsMap.set("Gérer les utilisateurs",{
  attachments: {
    queryParam: 'gestion-utilisateurs',
  },
  component: () => ManageUsers,
  validationFunction({ user }) {
    if (user.type !== "admin")
      return false;
    return true;
  },
});
tabsMap.set("Informations", {
  attachments: {
    queryParam: 'parametres', 
  },
  component: () => Settings
});
tabsMap.set("Paiement", {
  attachments: {
    queryParam: 'paiement', 
  },
  component: () => Payment,
  validationFunction({ user }) {
    if (user.profile === "freelance")
      return false;
    return true;
  },
});

export const abstractChoice = new AbstractChoice({ tabsMap });

export default function ProfilePage() {
  const location = useLocation();
  const [auth] = useContext(AuthContext);
  const [user, setUser] = useState(null);
  const [shouldRefreshUser, setShouldRefreshUser] = useState(false);
  const [loading, setLoading] = useState(false);

  const [loadedFields, setLoadedFields] = useState([]);
  const [loadedWorkLocations, setLoadedWorkLocations] = useState([]);

  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [selfDelete, setSelfDelete] = useState(false);
  const [nameSpace, setNameSpace] = useState('profiles');

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    if (!user) return;
    abstractChoice.findFirstValidTab({ user })
    forceUpdate();
  }, [user]);


  // et d'afficher le matching le cas échéant
  useEffect(() => {
    if (user) {
      // Permet de vérifier au chargement de la page si on souhaite naviguer vers un onglet particulier
      const params = new URLSearchParams(location.search);
      abstractChoice.changeTabWithCondition(({ key }) => {
        return abstractChoice.getAttachments({ tab: key }).queryParam === params.get("onglet");
      });
      //forceUpdate();
    }
  }, [location.search, user]);

  useEffect(() => {
    if (auth.logged) {
      fetchAllFieldsByCategories((_fields) => setLoadedFields(_fields));
      fetchAllWorkLocations((_locations) =>
        setLoadedWorkLocations(_locations)
      );
    }
  }, [auth]);


  useEffect(() => {
    if (auth.logged) {
      setLoading(true);
      get(`/users/${auth.userId}`).then((user) => {
        if (user) {
          setUser(user);
        }
        setLoading(false);
      });
    }
    setShouldRefreshUser(false);
  }, [auth, shouldRefreshUser]);

  const deleteUser = (user, selfDelete = false) => {
    setUserToDelete(user);
    setSelfDelete(selfDelete);
    setShowDeleteUserModal(true);
  };

  const CurrentComponent = abstractChoice.currentComponent({
    conditions: {user},
    props: {
      nameSpace,
      setNameSpace,
      user,
      setUser,
      deleteUser,
      loadedFields,
      loadedWorkLocations,
      setShouldRefreshUser,
      auth,
    }
  });

  return (
    <PageContainer>
      <Sidebar
        currentTab={abstractChoice.currentTab}
        user={user}
      />
      <ContentWrapper>
      { loading ?
        <CircularProgress />
        : <>{ CurrentComponent }</>
      }
      </ContentWrapper>
      <DeleteUserModal
        showModal={showDeleteUserModal}
        closeModal={() => setShowDeleteUserModal(false)}
        user={userToDelete}
        setUser={setUser}
        selfDelete={selfDelete}
      />
    </PageContainer>
  );
}

const PageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  width: 100%;
  padding: 35px 70px;
  min-height: 90vh;
  text-align: left;
  background-image: url("/assets/images/plantes-roses.svg");
  background-repeat: no-repeat;
  background-position: 105% 100%;
  background-size: calc(15em + 5vw);

  @media (max-width: 1450px) {
    padding: 30px;
  }
`;
