import { useState, useEffect } from "react";
import styled from "styled-components";
import ModalTemplate from "../../shared/components/modal-template.component";
import ProfileCard from "../../specific/components/personal-account/profiles/profile-card.component";
import Checkbox from '../../shared/components/inputs/checkbox-input.component.js';
import Button from "../../shared/components/buttons/button.component";
import { AlertWrapper } from './delete-user.modal';
import { Alert } from "@material-ui/lab";
import {get, post} from "../../shared/utils/api.utils";
import { MainWrapper as CardLayout } from "../../shared/components/item-card-layout.component";
import { Paragraph } from "../../shared/utils/styled-components-library.utils";
import { useHistory } from "react-router-dom";
import Image from "../../shared/components/images/image.component";
import ConfirmModal from "../../shared/modals/confirm-modal";

export default function AnswerNeedModal(props) {
	const { user, needId, ...modalProps } = props;
	const [ profilePositions, setProfilePositions ] = useState([]);
	const [updating, setUpdating] = useState(false);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [alert, setAlert] = useState({ show: false, type: null, message: '' });
        const history = useHistory();

	useEffect(() => {
		get(`/candidates/?needHash=${needId}`).then(response => {
			if (response) {
				setProfilePositions(
                                  user.profiles
                                    .filter((profile) => (profile.isActive === 1))
                                    .map((profile) => {
					const candidate = response.find((candidate) => candidate.profileId === profile.profileId);
					return ({
						needId,
						profileId: profile.profileId,
						toCreate: candidate ? true : false,
					})
				    })
                                );
			} else {
				setAlert({ show: true, type: "warning", message: "Une erreur est survenue lors de la récupération des candidatures, veuillez réessayer ultérieurement." });
			}
		});
	}, []);
	

	const updatePositions = () => {
		setAlert({ show: false, type: null, message: null });
		setUpdating(true);
		post(`/candidates`, profilePositions).then(response => {
			if (response && response.success) {
				setAlert({ show: true, type: "success", message: "Les positionnements ont été mis à jour avec succès." });
			} else {
				setAlert({ show: true, type: "error", message: response.message ?? "Une erreur est survenue lors de la tentative de de mise à jour, veuillez réessayer ultérieurement." });
			}
		});
		setUpdating(false);
	}

	const updatePosition = (profileId, checked) => {
		const newProfilePositions = profilePositions.map((profile) => {
			if (profile.profileId === profileId) {
				return { ...profile, toCreate: checked };
			}
			return profile;
		});
		setProfilePositions(newProfilePositions);
	};

	const renderCard = (profile) => {
		const cardProps = { nameSpace: 'profiles', profile, user };
		const candidate = profilePositions.find((profilePosition) => profilePosition.profileId === profile.profileId);
		let bottom = 
			<CheckboxWrapper>
				<Checkbox label="Positionner" checked={candidate?.toCreate === true} onChangeHandler={(event) => updatePosition(profile.profileId, event.target.checked)} />
			</CheckboxWrapper>
		return (
			<ProfileCard
                          isPrivate
                          key={profile.hash}
                          {...cardProps} bottom={bottom}
                        />
		);
	};

	return (
          <>
		<ModalTemplate
			width="1050px"
			{...modalProps}
			closeModal={() => {
				modalProps.closeModal();
				setAlert({ show: false, type: null, message: null });
			}}
		>
			<MainWrapper>
				<CustomTitle>
					Positionner un ou plusieurs dossiers
				</CustomTitle>
				<ProfileContainer>
				{ user && profilePositions !== undefined &&
					user.profiles
                                          .filter((profile) => profile.isActive === 1)
                                          .map((profile) => (
						renderCard(profile)
                                          ))
				}
                                {
                                  user && user.profile !== 'freelance' && 
                                    <CardLayout onClick={() => history.push('/creer-interco')} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', height: 355}}>
                                      <div style={{ borderRadius: 40, flex: 1, width: '100%', height: '100%', fontWeight: 'bold', border: "5px solid #f296ac", display: 'flex', flexDirection: 'column', gap: 20, alignItems: 'center', justifyContent: 'center' }}>
                                        <Paragraph style={{fontSize: 20}}>
                                          Créer un intercontrat
                                        </Paragraph>
                                        <Image src="/assets/icons/plus.png" alt="plus" style={{ width: 50, height: 50, marginRight: 20 }} />
                                      </div>
                                    </CardLayout>
                                }
				</ProfileContainer>
				{
					alert.show && <AlertWrapper>
						<Alert severity={alert.type}>{alert.message}</Alert>
					</AlertWrapper>
				}
				<ButtonContainer>
					<Button
						disabled={updating}
						outlineButton
						onClick={() => {
							setAlert({ show: false, type: null, message: null });
							modalProps.closeModal();
						}}
						style={{ width: '150px' }}
					>
						Annuler
					</Button>
					<Button
						onClick={() => setShowConfirmModal(true)}
						style={{ width: '150px', padding: '20px 0' }}
					>
						Mettre à jour les positionnements
					</Button>
				</ButtonContainer>
			</MainWrapper>
		</ModalTemplate>
            <ConfirmModal 
              onConfirm={() => updatePositions()}
              onCancel={() => setShowConfirmModal(false)}
              showModal={showConfirmModal}
              message={"Êtes-vous sûr de vouloir positionner ces profils ?"}
              closeModal={() => setShowConfirmModal(false)}
            />
          </>
	);
}

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70vh;
  overflow-y: auto;
  margin-right: -20px;
  margin-bottom: 40px;
`;

const ButtonContainer = styled.div`
	margin: 30px 0;
	width: 60%;
  display: flex;
  flex-direction: row;
	justify-content: space-between;
	gap: 50px;
`;

const ProfileContainer = styled.div`
	margin: 0 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

`;

const CustomTitle = styled.h2`
  color: #241f47;
  font-family: "Prompt Semi Bold";
  font-size: 30px;
  margin: 0;
	margin-bottom: 30px;
`;

const CheckboxWrapper = styled.div`
	display: flex;
	justify-content: center;
`;
