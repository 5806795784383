import React, { useState } from "react";
import styled from "styled-components";
import { Alert } from "@material-ui/lab";

import { BigTitle } from "../../shared/utils/styled-components-library.utils";
import TextInput from "../../shared/components/inputs/text-input.component";
import Button from "../../shared/components/buttons/button.component";
import { isValid } from "../../shared/utils/input-validation.utils";
import { post } from "../../shared/utils/api.utils";
import ContactInfo from "../../shared/components/contact-info.component";
import Image from "../../shared/components/images/image.component";

export default function ContactUs() {
	const [email, setEmail] = useState("");
	const [firstname, setFirstname] = useState("");
	const [lastname, setLastname] = useState("");
	const [subject, setSubject] = useState("");
	const [content, setContent] = useState("");
	const [phone, setPhone] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [showErrors, setShowErrors] = useState(false);
	const [alert, setAlert] = useState({ show: false, type: null, message: null });

	const submitForm = () => {
		if (!isValid("email", email.trim()) || !firstname.trim() || !lastname.trim() || !subject.trim() || !content.trim() || (!!phone.trim() && !isValid("phone", phone.trim()))) {
			setShowErrors(true);
		} else {
			setShowErrors(false);
			setAlert({ show: false, type: null, message: null });
			post("/misc/contact-us", {
				email: email.trim(),
				firstname: firstname.trim(),
				lastname: lastname.trim(),
				subject: subject.trim(),
				content: content.trim(),
				phone: phone.trim().replace(/ /g, ""),
				companyName: companyName.trim()
			}).then(response => {
				if (response && response.success) {
					setEmail("");
					setFirstname("");
					setLastname("");
					setSubject("");
					setContent("");
					setPhone("");
					setCompanyName("");
					setAlert({ show: true, type: "success", message: "Un mail a été envoyé avec succès à l'équipe 1terco !" });
				} else {
					setAlert({ show: true, type: "error", message: "Une erreur s'est produite lors de l'envoi de vos informations. Veuillez essayer ultérieurement si le problème se reproduit." });
				}
			});
		}
	}

	return (
		<PageContainer>
			<BigTitle>
				NOUS CONTACTER
			</BigTitle>
			<ContactInfoWrapper>
				<ContactInfo />
			</ContactInfoWrapper>
			<FormContainer>
				<FormNameInputsContainer>
					<TextInput value={lastname} onChangeHandler={(e) => setLastname(e.target.value)} label="Nom" required error={showErrors && !lastname.trim()} errorText="Ce champ est obligatoire." />
					<TextInput value={firstname} onChangeHandler={(e) => setFirstname(e.target.value)} label="Prénom" required error={showErrors && !firstname.trim()} errorText="Ce champ est obligatoire." />
				</FormNameInputsContainer>
				<TextInput value={email} onChangeHandler={(e) => setEmail(e.target.value)} label="Mail" required error={showErrors && !isValid("email", email.trim())} errorText={!email ? "Ce champ est obligatoire." : "L'adresse e-mail n'est pas valide."} />
				<TextInput value={phone} onChangeHandler={(e) => setPhone(e.target.value)} label="Téléphone" error={showErrors && !!phone.trim() && !isValid("phone", phone.trim())} errorText="Le format du numéro de téléphone entré n'est pas valide." />
				<TextInput value={companyName} onChangeHandler={(e) => setCompanyName(e.target.value)} label="Nom de l'entreprise" />
				<TextInput value={subject} onChangeHandler={(e) => setSubject(e.target.value)} label="Sujet" required error={showErrors && !subject.trim()} errorText="Ce champ est obligatoire." />
				<TextInput value={content} onChangeHandler={(e) => setContent(e.target.value)} label="Votre message" required multiline rows={8} error={showErrors && !content.trim()} errorText="Ce champ est obligatoire." />
				<SubmitButtonContainer>
					<Button onClick={submitForm} uppercase>Envoyer</Button>
				</SubmitButtonContainer>
				{
					alert.show && <Alert severity={alert.type}>{alert.message}</Alert>
				}
			</FormContainer>
			<ImagesContainer>
				<Image src="/assets/images/plante-rose-opaque.png" width="calc(15em + 8vw)"/>
				<Image src="/assets/images/plante-rose-bis.png" width="calc(10em + 5vw)"/>
				<Image src="/assets/images/contact-left.png" width="calc(15em + 10vw)"/>
				<Image src="/assets/images/plante-bleue.png" width="calc(10em + 5vw)"/>
			</ImagesContainer>
		</PageContainer>
	);
}

const PageContainer = styled.div`
	margin-bottom: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const FormContainer = styled.div`
	max-width: 540px;
	margin: auto;
	padding: 0 2em;
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 5;
`;

const FormNameInputsContainer = styled.div`
	display: flex;
	justify-content: space-between;

	& > * {
		width: 48%;
	}
`;

const SubmitButtonContainer = styled.div`
	margin-bottom: 20px;
`;

const ContactInfoWrapper = styled.div`
	margin-bottom: 40px;
	z-index: 5;
`;

const ImagesContainer = styled.div`
	position: absolute;
	height: 100%;
	width: 100%;

	& > div {
		position: absolute;
	}

	& > div:nth-child(1) {
		right: -6em;
		top: 10%;
		z-index: 3
	}

	& > div:nth-child(2) {
		right: -2em;
		top: 25%;
		z-index: 2
	}

	& > div:nth-child(3) {
		left: -4.5em;
		top: 35%;
		z-index: 4;
	}

	& > div:nth-child(4) {
		left: -2.5em;
		top: 80%;
		z-index: 1;
	}
`;