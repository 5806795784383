import React, { useContext, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import { CircularProgress } from "@material-ui/core";
import { getToken, loadTokenPayload } from "../shared/utils/auth.utils";
import { AuthContext } from "../contexts/auth.context";
import { CreateIntercoProvider } from "../contexts/create-interco.context";
import { CreateNeedProvider } from "../contexts/create-need.context";
import { FiltersProvider, FiltersContext } from "../contexts/filters.context";
import {
  NeedFiltersProvider,
  NeedFiltersContext,
} from "../contexts/need-filters.context";
import Header from "../shared/components/layout/header.component";
import Footer from "../shared/components/layout/footer.component";
import ScrollToTop from "../specific/components/scroll-to-top.component";
import SignInModal from "../shared/components/sign-in-modal.component";

/* ROUTES PROTEGEES */
import CreateInterco from "../pages/auth/create-interco.page";
import CreateNeed from "../pages/auth/create-need.page";
import Profile from "../pages/auth/profile.page";
import PersonalAccount from "../pages/auth/personal-account.page"; // espace personnel
import { get } from "../shared/utils/api.utils";

/* ROUTES PUBLIQUES */
import Home from "../pages/public/home.page";
import SignUp from "../pages/public/sign-up.page";
import SignUpEmail from "../pages/public/sign-up-email.page";
import UpdatePassword from "../pages/public/update-password.page";
import ContactUs from "../pages/public/contact-us.page";
import About from "../pages/public/about.page";
import ListingItems from "../pages/public/listing-items.page";
import LegalNotice from "../pages/public/legal-notice.page";
import NotFound from "../pages/not-found.page";

/* ROUTES IMPRESSIONS PDF */
import ProfileCardPdf from "../pages/pdf/profile-card-pdf.page";
import { applyFilters, getProfiles } from "../shared/utils/profiles.utils";
import { getNeeds } from "../shared/utils/needs.utils";

/**
 * Composant contenant l'ensemble des routes qui peuvent être accédées publiquement.
 */
export default function AppRouter() {
  const isPdfRender = window.location.pathname.includes("/pdf");
  const [authState, setAuthState] = useContext(AuthContext);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [signInRedirectRoute, setSignInRedirectRoute] = useState(null);
  const [loadingToken, setLoadingToken] = useState(true);

  useEffect(() => {
    const token = getToken();
    const payload = loadTokenPayload(token);
    if (!payload) {
      setAuthState((state) => ({ ...state, logged: false }));
      const params = new URL(document.location).searchParams;
      if (params.has("login")) {
        setShowSignInModal(true);
      } else {
        setShowSignInModal(false);
      }
    } else {
      const isLogged = payload && payload.profile && payload.type;
      setAuthState((state) => ({
        ...state,
        logged: isLogged,
        profile: payload.profile || null,
        type: payload.type || null,
        userId: payload.id || null,
      }));
    }
    setLoadingToken(false);
  }, [setAuthState]);

  const protectedProps = {
    shouldRedirect: !(loadingToken || authState.logged), // condition de redirection pour les routes protégées
  };

  const handleOpenSignInModal = (redirect) => {
    setShowSignInModal(true);
    setSignInRedirectRoute(redirect);
  };

  return (
    <Router>
      {isPdfRender ? (
        <Switch>
          <Route path="/pdf/profil/:hash" component={ProfileCardPdf} />
          <Route component={null} />
        </Switch>
      ) : (
        <>
          <Header openSignInModal={handleOpenSignInModal} />
          <Switch>
            <PaidRoute
              exact
              path="/profil/:hash"
              render={(props) => <Profile nameSpace="profiles" {...props} />}
              {...protectedProps}
            />
            <PaidRoute
              exact
              path="/besoin/:hash"
              profile={authState?.profile}
              render={(props) => <Profile nameSpace="needs" {...props} />}
              {...protectedProps}
            />
            <ProtectedRoute
              exact
              path="/espace-personnel"
              render={() => <PersonalAccount />}
              {...protectedProps}
            />
            {/* Route de création d'un profil sont protégées uniquement à partir de la deuxième étape */}
            <PaidRoute
              exact
              path="/creer-interco"
              profile={authState?.profile}
              render={() => (
                <CreateIntercoProvider>
                  <CreateInterco
                    openSignInModal={() => setShowSignInModal(true)}
                  />
                </CreateIntercoProvider>
              )}
              {...protectedProps}
            />
            <PaidRoute
              exact
              path="/creer-besoin"
              profile={authState?.profile}
              render={() => (
                <CreateNeedProvider>
                  <CreateNeed
                    openSignInModal={() => setShowSignInModal(true)}
                  />
                </CreateNeedProvider>
              )}
              {...protectedProps}
            />
            <ProtectedRoute
              exact
              path="/creer-fiche-profil"
              render={() => (
                <CreateIntercoProvider>
                  <CreateInterco
                    isFreelance
                    openSignInModal={() => setShowSignInModal(true)}
                  />
                </CreateIntercoProvider>
              )}
              {...protectedProps}
            />
            <Route exact path="/inscription-email" component={SignUpEmail} />
            <Route exact path="/inscription" component={SignUp} />
            <Route
              exact
              path="/modifier-mot-de-passe"
              component={UpdatePassword}
            />
            <Route exact path="/nous-contacter" component={ContactUs} />
            <Route exact path="/a-propos" component={About} />
            <Route exact path="/mentions-legales" component={LegalNotice} />
            <Route
              exact
              path="/bourse-interco"
              render={() => (
                <FiltersProvider>
                  <ListingItems
                    applyFilters={applyFilters}
                    openSignInModal={() => setShowSignInModal(true)}
                    context={FiltersContext}
                    getItems={getProfiles}
                  />
                </FiltersProvider>
              )}
            />
            <Route
              exact
              path="/liste-besoin"
              render={() => (
                <NeedFiltersProvider>
                  <ListingItems
                    applyFilters={applyFilters}
                    openSignInModal={() => setShowSignInModal(true)}
                    context={NeedFiltersContext}
                    getItems={getNeeds}
                  />
                </NeedFiltersProvider>
              )}
            />
            <Route
              exact
              path="/"
              render={() => (
                <Home openSignInModal={() => setShowSignInModal(true)} />
              )}
            />
            <Route>
              <NotFound />
            </Route>
          </Switch>
          <Footer openSignInModal={() => setShowSignInModal(true)} />
          <SignInModal
            showModal={showSignInModal}
            closeModal={() => setShowSignInModal(false)}
            redirect={signInRedirectRoute}
          />
          <ScrollToTop />
        </>
      )}
    </Router>
  );
}

const ProtectedRoute = (props) => (
  <>
    {props.shouldRedirect ? (
      <Redirect to="/" />
    ) : (
      <Route path={props.path} render={props.render} />
    )}
  </>
);

const PaidRoute = (props) => {
  const [loading, setLoading] = useState(true);
  const [paying, setPaying] = useState(false);

  useEffect(() => {
    get("/stripe/has-paid").then((res) => {
      if (res.hasPaid) {
        setPaying(true);
      }
      setLoading(false);
    });
  }, []);

  if (props.shouldRedirect) {
    return <Redirect to="/" />;
  }

  if (!loading && !paying && props.profile !== "freelance") {
    return <Redirect to="/espace-personnel?onglet=paiement" />;
  }

  if (!loading && (paying || props.profile === "freelance")) {
    return <Route path={props.path} render={props.render} />;
  }
  return <CircularProgress />;
};
