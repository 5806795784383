import React, { useState, useEffect } from "react";
import "./App.css";
import { AuthProvider } from "./contexts/auth.context";
import AppRouter from "./navigation/app-router.navigation";

function App() {
	const [code, setCode] = useState(null);

	// !! TODO : supprimer cette partie lorsqu'on mettra le site en prod
	useEffect(() => {
	/*	let allowed = window.localStorage.getItem("allowed");
		if (!allowed) {
			let input = prompt("Set code");
			if (input === "admin123") {
				setCode("allowed");
				window.localStorage.setItem("allowed", "allowed");
			}
		} else {
			setCode("allowed");
		}*/
		window.localStorage.setItem("allowed", "allowed");
		setCode("allowed");
	}, []);

	return (
		<div className="App">
			{code && (
				<AuthProvider>
					<AppRouter />
				</AuthProvider>
			)}
		</div>
	);
}

export default App;
