import React, { useState, useContext, useEffect, useRef } from 'react';
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import ReCAPTCHA from "react-google-recaptcha";

import { AuthContext } from "../../contexts/auth.context";
import Image from "../../shared/components/images/image.component";
import Button from "../../shared/components/buttons/button.component";
import TextInput from "../../shared/components/inputs/text-input.component";
import { isValid } from "../../shared/utils/input-validation.utils";
import { BigTitle } from "../../shared/utils/styled-components-library.utils";
import { post } from "../../shared/utils/api.utils";

export default function SignUpEmail() {
	const [authState] = useContext(AuthContext);
	const [isAlreadyLogged, setIsAlreadyLogged] = useState(false);
	const [email, setEmail] = useState("");
	const [submittingEmail, setSubmittingEmail] = useState(false);
	const [emailSent, setEmailSent] = useState(false);
	const [alert, setAlert] = useState({ show: false, type: null, message: null });
	const [showErrors, setShowErrors] = useState(false);
	const recaptchaRef = useRef();

	useEffect(() => {
		if (authState.logged) {
			setIsAlreadyLogged(true);
		}
	}, [authState]);

	const sendEmail = async () => {
		if (emailSent) {
			return;
		}

		if (isValid('email', email) && !submittingEmail && alert.type !== "info") {
			setShowErrors(false);
			setSubmittingEmail(true);
			recaptchaRef.current.reset();
			const recaptchaToken = await recaptchaRef.current.executeAsync();
			post("/auth/signup-email", { email, recaptchaToken }).then((response) => {
				setSubmittingEmail(false);
				if (response && response.success) {
					setEmailSent(true);
					setAlert({ show: true, type: "info", message: "Un email vous a été envoyé, merci de le consulter pour continuer votre inscription." });
				} else if (response && !response.success && response.reason === "captcha") {
					setAlert({ show: true, type: "error", message: "Erreur de validation du captcha." });
				} else {
					setAlert({ show: true, type: "error", message: "Cet email est déjà utilisé par un utilisateur de la plateforme 1terco." });
				}
			});
		} else {
			setShowErrors(true);
		}
	}

	return (
		<SignUpBody>
			{
				isAlreadyLogged ? <Redirect to="/" /> : null
			}
			<SignUpFormContainer>
				<BigTitle>
					<div>INSCRIVEZ VOTRE TRIBU</div>
					<div>OU REJOIGNEZ-LÀ&nbsp;!</div>
				</BigTitle>
				<TextInput
					value={email}
					onChangeHandler={(e) => {
						if (alert.show) {
							setAlert({ show: false, type: null, message: null });
						}
						setEmail(e.target.value);
					}}
					label="Adresse e-mail"
					required
					error={showErrors && (!email.trim() || !isValid('email', email))}
					errorText="Le format de l'adresse e-mail n'est pas valide"
				/>
				<SendEmailButtonContainer>
					{
						alert.show && <Alert severity={alert.type}>{alert.message}</Alert>
					}
					{
						submittingEmail ?
							<CircularProgress /> :
							<>
								{
									!emailSent ? <Button onClick={sendEmail} uppercase>
										Suivant
									</Button> : null
								}
							</>
					}
				</SendEmailButtonContainer>
				<ReCAPTCHA
					ref={recaptchaRef}
					size="invisible"
					sitekey="6Lc6CQwcAAAAAGjkv9mjAjSgm0aSLrHcDSAWtCpc"
				/>
			</SignUpFormContainer>
			<GorillaImageContainer>
				<Image width="90%" src="/assets/images/gorille-porte.png" alt="gorilla entering" />
			</GorillaImageContainer>
			<WeedImageContainer />
		</SignUpBody>
	);
}


const SignUpBody = styled.div`
	width: 90%;
	margin: 0px 2% 0px 8%;
	display: flex;
	justify-content: center;
	margin-bottom: 50px;

	@media screen and (max-width: 520px) {
		width: unset;
		display: block;
		margin: 0px 8% 0px;
	}
`;

const SignUpFormContainer = styled.div`
	position: relative;
	width: 40%;
	margin-left: 10%;
	margin-top: 5vw;
	text-align: left;
	z-index: 1;

	@media screen and (max-width: 520px) {
		width: unset;
		margin-left: 0;
	}
`;

const GorillaImageContainer = styled.div`
	position: relative;
	width: 40%;
	margin-right: 5%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	z-index: 1;

	@media screen and (max-width: 520px) {
		display: none;
	}
`;

const WeedImageContainer = styled.div`
	position: absolute;
	width: 100vw;
	height: 87vh;
	right: 0;
	bottom: 0;
	background-image: url('/assets/images/plante-rose.png');
	background-size: calc(15em + 7vw);
	background-repeat: no-repeat;
	background-position-x: right;
	z-index: 0;
`;

const SendEmailButtonContainer = styled.div`
	display:flex;
	justify-content: flex-end;
	align-items: center;

	& > * {
		margin-left: 10px;
	}
`;
