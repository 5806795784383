import styled from "styled-components";
import { Link } from "react-router-dom";
import { withStyles, Tooltip } from "@material-ui/core";

const responsiveMaxWidth = "800px";

export const ButtonsContainer = styled.div`
  display: flex; 
  align-items: center;
	& > * {
		margin: 0 9px;
	}
`;

export const HorizontalSeparator = styled.div`
  width: 100%;
	box-sizing: border-box;
  border-top: 1px solid ${props => props.color ? props.color : "#F2CED2"};
  margin: ${props => props.marginVertical ? `${props.marginVertical}px 0px` : "0px"};
`;

export const StyledLink = styled(Link)`
  color: inherit;
	text-decoration: none;
	cursor: pointer;
`;

export const BigTitle = styled.h2`
	text-align: center;
	font-weight: ${props => props.bold ? "bold" : "normal"};
	font-size: 54px;
	color: #241f47;
	font-family: "Prompt Black";

	@media screen and (max-width: ${responsiveMaxWidth}) {
		font-size: 34px;
	}
`;

export const MediumTitle = styled.h3`
	font-weight: ${props => props.bold ? "bold" : "normal"};
	font-size: 24px;
	color: #241f47;
	font-family: "Prompt Semi Bold";
`;

export const Paragraph = styled.p`
	font-family: "Prompt";
	font-size: 16px;
	margin: 0;
	color: #333;
	line-height: 24px;
	text-align: ${props => props.align ? props.align : "left"};

	& span {
		display: block;
	}
`;

export const BigTooltip = withStyles({
	tooltip: {
		backgroundColor: "#FFF",
		color: "rgba(0, 0, 0, 0.87)",
		boxShadow: "0px 0px 25px 8px rgba(0,0,0,0.35);",
		fontSize: 10.5,
		maxWidth: 800,
	},
})(Tooltip);

export const LightTooltip = withStyles({
	tooltip: {
		backgroundColor: "#FFF",
		color: "rgba(0, 0, 0, 0.87)",
		boxShadow: "0px 0px 5px 2px rgba(0,0,0,0.35);",
		fontSize: 12,
		maxWidth: 600,
	},
	tooltipPlacementTop: {
		margin: "4px 0"
	}
})(Tooltip);

export const GoBack = styled.div`
	width: 60px;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #F194AC;
	cursor: pointer;

	& span {
		font-size: 12px;
		font-family: "Prompt Light";
		color: #42387a;
	}
`;