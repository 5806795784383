import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";

import Image from "../../../shared/components/images/image.component";
import { LightTooltip } from "../../../shared/utils/styled-components-library.utils";
import ContactItemOwnerModal from "../../modals/contact-profile-user.modal";
import AnswerNeedModal from "../../modals/answer-need.modal";
import ProfileActivity from "../../../shared/components/profile-activity.component";
import ProfileCardActions from "../personal-account/profiles/profile-card-actions.component";
import { getLogo } from "../../../shared/utils/items.utils";

/**
 * @param {object} props
 * @param {boolean} props.isPrivateSidebar Permet de déterminer si la sidebar est affichée sur une page
 * publique ou sur une page de l'espace personnel.
 */
export default function ProfileActionBar({
	nameSpace,
	user,
	profile,
  permissions,
	isPrivateSidebar,
	updateProfileActivation,
	updateProfile,
	deleteProfile,
	renewProfile,
	style,
  setShouldRefreshUser
}) {
	const [showContactModal, setShowContactModal] = useState(false);
	const [showAnswerNeedModal, setShowAnswerNeedModal] = useState(false);
        const whomToContact =
          nameSpace === 'needs'
            ? "demandeur"
            : profile.user.profile === "freelance" ? "freelance" : "chargé d'affaire";
	const skillsTooltipProps = {
		placement: "top",
		title: !!profile.anonymous
			? isPrivateSidebar
				? "Le dossier de compétences n'est pas accessible par les autres tribus car la fiche profil est anonymisée"
				: `Le dossier de compétences n'est pas disponible car le profil est anonymisé. Vous pouvez contacter l'utilisateur grâce à "Contacter le ${whomToContact}".`
			: !profile.files?.length
				? `Le ${profile.user.profile === "freelance" ? "CV" : "dossier de compétences"} n'a pas été renseigné`
				: ""
	}
	// on n'affiche pas de tooltip si le dossier de compétences est disponible
	if (!!profile.files?.length && !profile.anonymous && !isPrivateSidebar) {
		skillsTooltipProps.open = false;
	}
	
	const answerTooltipProps = {
		placement: "top",
		title: user?.profiles?.length === 0 ? "Vous n'avez pas de profil, vous ne pouvez pas répondre à ce besoin" : ""
	};

	return (
		<MainWrapper style={style}>
			{!isPrivateSidebar && (
				<AvatarWrapper>
          <Image
            width="195px"
            borderRadius="50%"
            src={ getLogo(nameSpace, profile, profile.user.profile, permissions, true) }
          />
          { permissions.anonymous &&
              <span className="anonymous">{nameSpace === "profiles" ? "Profil" : "Besoin"}{" "}anonymisé</span>
          }
          { (!permissions.anonymous || permissions.ownerContact) &&
              <span className="type">
                {
                  profile.user.profile === "freelance"
                    ? "Freelance"
                    : `${profile.user.companyName}`
                }
              </span>
          }
          { permissions.ownerContact &&
            <>
              <span className="label">
                Coordonnées du{" "}
                {profile.user.profile === "freelance" ? "freelance" : "manager"}
              </span>
              <span className="fullname">{profile.user.fullname}</span>
              <span className="email">{profile.user.email}</span>
              <span className="phone">{profile.user.phone.replace(/(.{2})/g,"$1.").slice(0, -1)}</span>
            </>
          }
				</AvatarWrapper>
			)}
			{isPrivateSidebar && (
				<NameWrapper>
					{profile.user.profile !== "freelance" && (
						<>
							{profile.user.userId !== user.userId && (
								<div className="name">
									<b>Manager :</b>
									<br />
									<span>
										{profile.user.fullname}
										<br />
										{profile.user.phone}
									</span>
								</div>
							)}
							{ profile.firstName && <div className="name">
								<b>Interco :</b>
								<br />
								<span>{profile.firstName} {profile.lastName.toUpperCase()}</span>
							</div>
							}
						</>
					)}
					<div className="reference">{profile.hash}</div>
				</NameWrapper>
			)}
			<TjmWrapper>
				<div>
					<span className="label">TJM</span>
					<br />
					{profile.priceVisible || isPrivateSidebar ? (
						<span>{profile.price} €</span>
					) : (
							<span>N/C</span>
						)}
				</div>
				<div style={{ marginTop: "20px" }}>
					<span className="label">DATE DE MÀJ</span>
					<br />
					<span>{moment(profile.updatedAt).format("DD/MM/YYYY")}</span>
				</div>
			</TjmWrapper>
			<PublicActionsWrapper>
				{nameSpace !== 'needs' && <LightTooltip {...skillsTooltipProps}>
					<Action
						deactivate={
							!profile.files?.length ||
							(!!profile.anonymous && !isPrivateSidebar)
						}
					>
						<IconWrapper>
							<Image src="/assets/icons/download.png" width="38px" />
						</IconWrapper>
						<span className="label">
							{!!profile.files?.length &&
								(!profile.anonymous || isPrivateSidebar) ? (
									<a
										target="_blank"
										rel="noopener noreferrer"
										href={`https://1terco-uploads.s3.eu-west-3.amazonaws.com/userUploads/${profile.files[0] ? profile.files[0].fileName : ""}`}
									>
										Télécharger le{" "}
										{profile.user.profile === "freelance" ? "CV" : "Dossier de compétences"}
									</a>
								) : (
									`Télécharger le ${profile.user.profile === "freelance" ? "CV" : "Dossier de compétences"}`
								)}
						</span>
					</Action>
				</LightTooltip>}
				{nameSpace !== 'needs' && !profile.anonymous && (
					<Action>
						<IconWrapper>
							<Image src="/assets/icons/download.png" width="38px" />
						</IconWrapper>
						<span className="label">
							<a
								target="_blank"
								rel="noopener noreferrer"
								href={`/pdf/profil/${profile.hash}`}
							>
								Télécharger la fiche profil
							</a>
						</span>
					</Action>
				)}
				{ !isPrivateSidebar && (
					<Action onClick={() => setShowContactModal(true)}>
						<IconWrapper>
							<Image
                src={ `/assets/icons/${nameSpace === 'profiles' ? 'contact.png' : 'help.svg' }` }
                width="38px"
              />
						</IconWrapper>
						<span className="label">
              { nameSpace === 'profiles'
                ? `Contacter le ${profile.user.profile === "freelance" ? "freelance" : "chargé d'affaire"}`
                : 'Poser une question'
              }
						</span>
					</Action>
				)}
				{nameSpace === 'needs' && !isPrivateSidebar && user && permissions?.canAnswer === true &&
          <LightTooltip {...answerTooltipProps}>
            <span> {/* necessary as per https://v4.mui.com/components/tooltips/#disabled-elements */}
              <Action
                onClick={() => setShowAnswerNeedModal(true)}
                deactivate={user?.profiles?.length === 0}
              >
                <IconWrapper>
                  <Image src="/assets/icons/contact.png" width="38px" />
                </IconWrapper>
                <span className="label">
                  Répondre au besoin
                </span>
              </Action>
            </span>
          </LightTooltip>
        }
			</PublicActionsWrapper>
			{isPrivateSidebar && (
				<>
					<ActivityWrapper>
						<ProfileActivity nameSpace={nameSpace} active={profile.isActive} />
					</ActivityWrapper>
					<PrivateActionsWrapper>
            <ProfileCardActions
              profile={profile}
              user={user}
              updateProfile={updateProfile}
              deleteProfile={deleteProfile}
              updateProfileActivation={updateProfileActivation}
              renewProfile={renewProfile}
              nameSpace={nameSpace}
              setShouldRefreshUser={setShouldRefreshUser}
            />
					</PrivateActionsWrapper>
				</>
			)}
			<ContactItemOwnerModal
				showModal={showContactModal}
				closeModal={() => setShowContactModal(false)}
				item={profile}
        nameSpace={nameSpace}
			/>
			{ user && nameSpace !== 'profiles' && !isPrivateSidebar && <AnswerNeedModal
					user={user}
					showModal={showAnswerNeedModal}
					closeModal={() => setShowAnswerNeedModal(false)}
					needId={profile.needId}
					profile={profile}
				/> }
		</MainWrapper>
	);
}

const MainWrapper = styled.div`
	width: 250px;
	min-width: 250px;
	display: flex;
	flex-direction: column;
`;

const AvatarWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 18px;
	font-family: "Prompt";
	
	& .anonymous {
		color: #241f47;
		margin-top: 10px;
		margin-bottom: 15px;
	}

	& .label {
		font-size: 16px;
		color: #58567a;
		margin-top: 10px;
		font-family: "Prompt Light Italic";
	}

	& .fullname {
		color: #241f47;
	}
	
	& .phone, & .email {
		opacity: 0.7;
		color: #4f499c;
		font-family: "Prompt Light";
		font-size: 20px;
	}
	
	& .type {
		font-family: "Prompt Light Italic";
		opacity: 0.7;
		color: #817cc4;
	}
`;

const TjmWrapper = styled.div`
	padding: 16px 3em;
	width: 100%;
	background-color: #353f83;
	border-radius: 20px;
	color: #FFF;
	font-size: 16px;
	line-height: 16px;
	font-family: "Prompt";
	text-align: left;
	margin-top: 10px;
	
	& .label {
		opacity: 0.5;
		font-size: 16px;
	}
`;

const PublicActionsWrapper = styled.div`
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	
	& > * {
		margin: 10px 0px;
	}
`;

const Action = styled.div`
	display: flex;
	flex-direction: row;
	cursor: ${props => (props.deactivate ? "default" : "pointer")};
	pointer-events: ${props => (props.deactivate ? "none" : "auto")};
	opacity: ${props => (props.deactivate ? "0.7" : "1")};
	text-align: left;
	align-items: center;
	
	.label {
		margin-left: 14px;
		font-size: 16px;
		line-height: 19px;
		font-family: "Prompt";
		color: ${props => (props.deactivated ? "#241f4777" : "#241f47")};
		pointer-events: ${props => (props.deactivated ? "none" : "auto")};
	}

	& a {
		color: inherit;
	}
`;

const IconWrapper = styled.div`
	width: 38px;
	height: 38px;
`;

const NameWrapper = styled.div`
	margin: 0 0 10px 0;
	font-family: "Prompt";
	font-size: 16px;
	.name {
		color: #241f47;
		font-size: 25px;
		display: block;
		white-space: nowrap;
		text-overflow: ellipsis;
		width: 100%;
		overflow: hidden;
		line-height: 25px;
		margin-bottom: 30px;
	}

	.name > span {
		font-family: "Prompt Light";
		color: #7978a8;
		font-size: 21px;
	}

	.reference {
		font-family: "Prompt Light";
		color: #7978a8;
		font-size: 18px;
		text-decoration: underline;
	}

	& > * {
		margin-bottom: 10px;
	}
`;

const ActivityWrapper = styled.div`
	margin: 20px 20px 20px 0;

	& span {
		margin-left: 20px;
		font-size: 18px;
		color: #241f47;
	}
`;

const PrivateActionsWrapper = styled.div`
	display: flex;
	flex-direction: column;

	& > * {
		margin: 4px 0;
	}
`;
