import config from "../../config";
import { getToken, deleteToken } from "./auth.utils";

const getDefaultHeaders = () => {
  const currentToken = getToken();
  let defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (currentToken) {
    defaultHeaders["Authorization"] = `${currentToken}`;
  }
  return defaultHeaders;
};

export const get = async (path) => {
  return await fetch(config.apiUrl + path, {
    method: "GET",
    headers: getDefaultHeaders(),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401) {
          deleteToken();
          window.location.href = "/";
        }
        return false;
      }
    })
    .then((body) => {
      activationMiddleware(body);
      return body;
    })
    .catch((error) => {
      console.error(`Erreur durant le call API '${path}'`, error);
    });
};

export const post = async (path, body) => {
  return await fetch(config.apiUrl + path, {
    method: "POST",
    headers: getDefaultHeaders(),
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (!response.ok && response.status === 401) {
        deleteToken();
        window.location.href = "/";
      }
      if (response.headers.get("Content-Length") !== "0") {
        return response.json()
      }
      return response;
    })
    .then((data) => {
      if (data)
        activationMiddleware(data);
      return data;
    });
};

export const patch = async (path, body) => {
  return await fetch(config.apiUrl + path, {
    method: "PATCH",
    headers: getDefaultHeaders(),
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (!response.ok && response.status === 401) {
        deleteToken();
        window.location.href = "/";
      }
      response.json()
    })
    .then((body) => {
      activationMiddleware(body);
      return body;
    });
};

export const put = async (path, body) => {
  return await fetch(config.apiUrl + path, {
    method: "PUT",
    headers: getDefaultHeaders(),
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (!response.ok && response.status === 401) {
        deleteToken();
        window.location.href = "/";
      }
      response.json()
    })
    .then((body) => {
      activationMiddleware(body);
      return body;
    });
};

export const deleteReq = async (path, body) => {
  return await fetch(config.apiUrl + path, {
    method: "DELETE",
    headers: getDefaultHeaders(),
    body: body ? JSON.stringify(body) : undefined,
  })
    .then((response) => {
      if (!response.ok && response.status === 401) {
        deleteToken();
        window.location.href = "/";
      }
      if (response.headers.get("Content-Length") !== "0") {
        return response.json()
      }
      return response;
    })
    .then((data) => {
      if (data)
        activationMiddleware(data);
      return data;
    });
};

const activationMiddleware = (body) => {
  // si le compte a été suspendu par l'administrateur
  if (
    body.statusCode &&
    body.statusCode === "403" &&
    body.message === "ACCOUNT_DEACTIVATED"
  ) {
    deleteToken();
    window.location.href = "/";
    return false;
  }
  return true;
};
