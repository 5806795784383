import React, { useState, useContext, useEffect, useMemo } from "react";
import styled from "styled-components";
import { IconButton, Slider, withStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";

import TreeSelect from "./inputs/tree-select-input.component";
import CheckboxList from "./lists/checkbox-list.component";
import Image from "./images/image.component";

export default function ProfileFilters({
  workLocations,
  showCheckedNumber,
  saveConfig = false,
  pageLoaded = false,
  searchValue,
  loadedTjm,
  setLoadedTjm,
	context,
}) {
  const {
    nameSpace,
    filters,
    setFilters,
    filterItems,
    setFilterItems,
    resetFilters,
  } = useContext(context);
  const [tjm, setTjm] = useState(filters.tjm);
  const history = useHistory();
  const location = useLocation();
  const isListingPage = useMemo(
    () => location.pathname.includes("/bourse-interco"),
    [location]
  );
  /**
   * Fonction permettant de réinitialiser les filtres (contexte + affichage).
   */
  const resetAll = () => {
    setTjm([0, 900]);
    resetFilters();
    if (isListingPage) {
      window.location.href = "/bourse-interco";
    }
  };

  useEffect(() => {
    if (saveConfig && pageLoaded) {
      localStorage.setItem(
        `${nameSpace}-filters`,
        JSON.stringify({ ...filters, search: searchValue })
      );
    }
  }, [filters, saveConfig, pageLoaded, searchValue]);

  useEffect(() => {
    if (loadedTjm) {
      setTjm(loadedTjm);
      setLoadedTjm(null);
    }
  }, [filters, loadedTjm, setLoadedTjm]);

  /**
   * Fonction permettant de modifier les filtres sélectionnés.
   * @param {'fields' | 'profileType' | 'studyLevel' | 'englishLevel' | 'experienceYears' | 'availability' | 'workFormat'} type
   * @param {number} value
   */
  const toggleCheckHandler = (type, value) => {
    const items = getItems(type);
    const itemChecked = filters[type].find(
      (item) => item.value === value || item.label === value
    );
    if (!itemChecked) {
      // si l'option n'était pas sélectionnée auparavant, on l'ajoute au filtre
      const item = items.find(
        (_item) => _item.value === value || _item.label === value
      );
      setFilters((filters) => ({
        ...filters,
        [type]: filters[type].concat([item]),
      }));
      setFilterItems((items) => ({
        ...items,
        [type]: items[type].map((item) =>
          item.value === value || item.label === value
            ? { ...item, checked: !item.checked }
            : item
        ),
      }));
    } else {
      // si l'option était sélectionnée auparavant, on la retire des filtres
      setFilters((filters) => ({
        ...filters,
        [type]: filters[type].filter(
          (item) =>
            item.value !== itemChecked.value || item.label !== itemChecked.label
        ),
      }));
    }
    setFilterItems((items) => ({
      ...items,
      [type]: items[type].map((item) =>
        item.value === value || item.label === value
          ? { ...item, checked: !item.checked }
          : item
      ),
    }));
    // if (isListingPage) {
    //   const params = new URLSearchParams(location.search);
    //   let count = params.get("count") || 10;
    //   history.push({
    //     pathname: "/bourse-interco",
    //     search: `?page=1&count=${count}`,
    //   });
    // }
  };

  const getItems = (type) => {
    const items = {
      fields: filterItems.fields,
      profileType: filterItems.profileType,
      studyLevel: filterItems.studyLevel,
      englishLevel: filterItems.englishLevel,
      experienceYears: filterItems.experienceYears,
      availability: filterItems.availability,
      workFormat: filterItems.workFormat,
    };
    return items[type];
  };

  const commonListProps = { showCheckedNumber };
  return (
    <FiltersWrapper>
      <ResetWrapper>
        <span>Réinitialiser les filtres</span>
        <CloseImageWrapper>
          <Image
            src="/assets/icons/close.svg"
            alt="Icône fermeture"
            width="15px"
            onClick={resetAll}
          />
        </CloseImageWrapper>
      </ResetWrapper>
      <FiltersLabel>TJM (€)</FiltersLabel>
      <TjmInputsWrapper>
        <TjmValue>
          <div>{tjm[0]}</div>
        </TjmValue>
        <TjmValue>
          <div>{tjm[1]}</div>
        </TjmValue>
      </TjmInputsWrapper>
      <TjmSliderWrapper>
        <CustomSlider
          value={tjm}
          step={50}
          min={0}
          max={2000}
          onChange={(_, value) => setTjm(value)}
          onChangeCommitted={(_, value) =>
            setFilters((filters) => ({ ...filters, tjm: value }))
          }
          ThumbComponent={SliderThumb}
        />
      </TjmSliderWrapper>
      <TreeSelectWrapper>
        <TreeSelect
          treeData={workLocations}
          value={filters.workLocations}
          onChange={(value) =>
            setFilters((filters) => ({ ...filters, workLocations: value }))
          }
          placeholder={ nameSpace === "needs" ? "Localisation" : "Mobilité" }
        />
      </TreeSelectWrapper>
      { filterItems.fields && <CheckboxList
        groupItems={filterItems.fields}
        label="Secteurs d'activité"
        toggleCheckHandler={(value) => toggleCheckHandler("fields", value)}
        {...commonListProps}
      /> }
      { filterItems.profileType && <CheckboxList
        items={filterItems.profileType}
        label={ nameSpace === 'needs' ? "Emetteur" : "Type de profil" }
        toggleCheckHandler={(value) => toggleCheckHandler("profileType", value)}
        {...commonListProps}
      /> }
      { filterItems.studyLevel && <CheckboxList
        items={filterItems.studyLevel}
        label="Niveau d'études"
        toggleCheckHandler={(value) => toggleCheckHandler("studyLevel", value)}
        {...commonListProps}
      /> }
      { filterItems.englishLevel && <CheckboxList
        items={filterItems.englishLevel}
        label="Niveau d'anglais"
        toggleCheckHandler={(value) =>
          toggleCheckHandler("englishLevel", value)
        }
        {...commonListProps}
      /> }
      { filterItems.experienceYears && <CheckboxList
        items={filterItems.experienceYears}
        label="Expérience"
        toggleCheckHandler={(value) =>
          toggleCheckHandler("experienceYears", value)
        }
        {...commonListProps}
      /> }
      { filterItems.availability && <CheckboxList
        items={filterItems.availability}
        label="Disponibilité"
        toggleCheckHandler={(value) =>
          toggleCheckHandler("availability", value)
        }
        {...commonListProps}
      /> }
      { filterItems.workFormat && <CheckboxList
        items={filterItems.workFormat}
        label="Télétravail possible"
        toggleCheckHandler={(value) => toggleCheckHandler("workFormat", value)}
        {...commonListProps}
      /> }
    </FiltersWrapper>
  );
}

const SliderThumb = (props) => {
  return (
    <span {...props}>
      <ThumbSpan isMin={props["data-index"] === 0}>
        {props["data-index"] === 0 ? "-" : "+"}
      </ThumbSpan>
    </span>
  );
};

const ThumbSpan = styled.span`
  margin-top: ${(props) => (props.isMin ? "-2px" : "0")};
  color: #fff;
`;

const FiltersWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const FiltersLabel = styled.label`
  margin: 10px 0px;
  text-align: left;
  font-size: 14px;
  font-family: "Prompt";
  color: #42387a;
`;

const TjmInputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TjmSliderWrapper = styled.div`
  margin: 25px 0px;
`;

const TjmValue = styled.div`
  width: 46%;
  border: 1px solid #f194ac;
  border-radius: 4px;
  padding: 10px 16px;
  font-family: "Prompt";
  color: #42387a;
  display: flex;
  justify-content: center;
  font-size: 16px;
`;

const ResetWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Prompt";

  & > span {
    color: #bdb9d1;
    font-size: 12px;
  }
`;

const CloseImageWrapper = styled.div`
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const TreeSelectWrapper = styled.div`
  padding: 10px 0px;
  border-bottom: 1px solid #ccc;

  & .ant-select-selection-placeholder {
    color: #000;
  }
`;

const CustomSlider = withStyles({
  root: {
    color: "#F194AC",
    height: 3,
    padding: "13px 0",
  },
  thumb: {
    height: 27,
    width: 27,
    backgroundColor: "#F194AC",
    border: "1px solid currentColor",
    marginTop: -12,
    marginLeft: -13,
    boxShadow: "#ebebeb 0 2px 2px",
    "&:focus, &:hover, &$active": {
      boxShadow: "#ccc 0 2px 3px 1px",
    },
    "& .bar": {
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  track: {
    height: 3,
  },
  rail: {
    color: "#d8d8d8",
    opacity: 1,
    height: 3,
  },
})(Slider);
