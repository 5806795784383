import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import { useLocation, Redirect } from "react-router-dom";

import { AuthContext } from "../../contexts/auth.context";
import Image from "../../shared/components/images/image.component";
import EmailForm from "../../specific/components/signup/email-form.component";
import CompanyForm from "../../specific/components/signup/company-form.component";
import LogoForm from "../../specific/components/signup/logo-form.component";
import UserForm from "../../specific/components/signup/user-form.component";
import PasswordForm from "../../specific/components/signup/password-form.component";

import * as auth from "../../shared/utils/auth.utils";
import { get } from "../../shared/utils/api.utils";
import {
  fetchAllFields,
  fetchAllFieldsByCategories,
} from "../../shared/utils/fetch.utils";
import { post } from "../../shared/utils/api.utils";

export default function SignUp() {
  const location = useLocation();
  const [authState] = useContext(AuthContext);

  const [loading, setLoading] = useState(true);

  const [shouldRedirect, setShouldRedirect] = useState(false); // redirige vers la première page de l'inscription s'il vaut "true"
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState({});
  const [fields, setFields] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [formSubmittedError, setFormSubmittedError] = useState(false);
  const [isJoiningCompany, setIsJoiningCompany] = useState(false);
  const [firstOptionChosen, setFirstOptionChosen] = useState(null);

  const [userEmail, setUserEmail] = useState("");
  const [userProfile, setUserProfile] = useState("");
  const [userGender, setUserGender] = useState("");
  const [userLastname, setUserLastname] = useState("");
  const [userFirstname, setUserFirstname] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userCguAccepted, setUserCguAccepted] = useState(true);
  const [userConfirmPassword, setUserConfirmPassword] = useState("");
  const [userAcquisition, setUserAcquisition] = useState("");

  // uniquement utile pour les sociétés
  const [companyName, setCompanyName] = useState("");
  const [companyFields, setCompanyFields] = useState([]);
  const [companyWorkforce, setCompanyWorkforce] = useState("");
  const [companyZipcode, setCompanyZipcode] = useState("");
  const [companySiret, setCompanySiret] = useState("");
  const [companyAPE, setCompanyAPE] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [companyLogoFile, setCompanyLogoFile] = useState(null);
  const [
    companyLogoUploadedFilename,
    setCompanyLogoUploadedFilename,
  ] = useState("");
  const [joinedCompany, setJoinedCompany] = useState("");
  const [userJobTitle, setUserJobTitle] = useState("");

  // !! WARNING : un éventuel changement des labels entraîne un changement nécessaire dans la méthode 'renderCurrentForm()'
  const stepperLabels =
    !userProfile
    || (userProfile === "company" && !isJoiningCompany)
    || userProfile === "end-client"
      ? [
          "Mail",
          "Information société",
          "Logo",
          "Information utilisateur",
          "Mot de passe",
        ]
      : ["Mail", "Information utilisateur", "Mot de passe"];

  useEffect(() => {
    if (authState.logged) {
      setShouldRedirect(true);
    }

    const params = new URLSearchParams(location.search);

    // on a 2 query params encodé en base 64
    // - le premier est "email" et est obligatoire
    // - le deuxième est "societe" et il est optionnel, il contient le siret de la société
    let email = "";
    try {
      email = params.get("email") ? atob(params.get("email")) : null;
    } catch {
      // si on tombe dans le catch, c'est que l'utilisateur a changé manuellement le query param
      setShouldRedirect(true);
    }
    if (!email) {
      setShouldRedirect(true);
    } else {
		setLoading(false);
		setUserEmail(email);
		fetchAllFieldsByCategories((loadedFields) => setFields(loadedFields));
		getAllCompanies();
    }
  }, [location, authState]);

  const getAllCompanies = () => {
    get("/companies").then((companies) => {
      if (companies) {
        const formattedCompanies = companies.map((company) => ({
          value: company.companyId,
          label: company.name,
          id: company.companyId,
        }));
        setCompanies(formattedCompanies);
      }
    });
  };

  const handlePreviousStep = () => {
    setActiveStep((previousStep) => previousStep - 1);
  };

  const handleNextStep = () => {
    setActiveStep((previousStep) => previousStep + 1);
  };

  const handleStep = (step) => {
    setActiveStep(step);
  };

  const handleSubmit = () => {
    setFormSubmittedError(false);
    setSubmittingForm(true);
    const signUpFormData = {
      user: {
        email: userEmail.trim(),
        lastname: userLastname.trim(),
        firstname: userFirstname.trim(),
        profile: userProfile,
        phone: userPhoneNumber.trim().replace(/ /g, ""),
        password: userPassword,
        gender: userGender === 1 ? "male" : userGender === 2 ? "female" : null,
        companyId: isJoiningCompany ? joinedCompany.id : null,
        jobTitle: userJobTitle || null,
        acquisition: userAcquisition || null,
      },
      company: {
        name: companyName.trim().toUpperCase(),
        workforce: companyWorkforce,
        siret: companySiret.replace(/ /g, ""),
        zip: companyZipcode.trim(),
        ape: companyAPE,
        logo: companyLogoUploadedFilename,
        website: companyWebsite.trim() || null,
        fieldIds: companyFields.map((field) => field.fieldId),
      },
      freelance: {},
    };
    auth.signUp(signUpFormData).then((response) => {
      if (response && response.success) {
        auth
          .signIn(signUpFormData.user.email, signUpFormData.user.password)
          .then((res) => {
            window.location.href = "/espace-personnel";
          });
      } else {
        setFormSubmittedError(true);
        setSubmittingForm(false);
      }
    });
  };

  /**
   * Permet d'afficher le formulaire correspondant à l'étape actuelle du flux d'inscription.
   * @param {number} activeStep
   */
  const renderCurrentForm = (activeStep, labels) => {
    const commonProps = {
      handleNext: handleNextStep,
      handlePrevious: handlePreviousStep,
      handleStep,
      stepperLabels,
      activeStep,
      completedSteps,
      setCompletedSteps,
    };
    const activeLabel = labels[activeStep];
    switch (activeLabel) {
      case "Mail":
        return (
          <EmailForm
            {...commonProps}
            email={userEmail}
            onChange={(email) => setUserEmail(email)}
            setUserProfile={setUserProfile}
            firstOptionChosen={firstOptionChosen}
            setFirstOptionChosen={setFirstOptionChosen}
            setIsJoiningCompany={setIsJoiningCompany}
          />
        );
      case "Information société":
        return (
          <CompanyForm
            {...commonProps}
            fieldOptions={fields}
            companyName={companyName}
            companyFields={companyFields}
            companyWorkforce={companyWorkforce}
            companyZipcode={companyZipcode}
            companySiret={companySiret}
            companyAPE={companyAPE}
            companyWebsite={companyWebsite}
            setCompanyName={setCompanyName}
            setCompanyFields={setCompanyFields}
            setCompanyWorkforce={setCompanyWorkforce}
            setCompanyZipcode={setCompanyZipcode}
            setCompanySiret={setCompanySiret}
            setCompanyAPE={setCompanyAPE}
            setCompanyWebsite={setCompanyWebsite}
          />
        );
      case "Logo":
        return (
          <LogoForm
            {...commonProps}
            companyLogoFile={companyLogoFile}
            setCompanyLogoFile={setCompanyLogoFile}
            setCompanyLogoUploadedFilename={setCompanyLogoUploadedFilename}
          />
        );
      case "Information utilisateur":
        return (
          <UserForm
            {...commonProps}
            userProfile={userProfile}
            userGender={userGender}
            userLastname={userLastname}
            userFirstname={userFirstname}
            userPhoneNumber={userPhoneNumber}
            userJobTitle={userJobTitle}
            isJoiningCompany={isJoiningCompany}
            joinedCompany={joinedCompany}
            setJoinedCompany={setJoinedCompany}
            companiesOptions={companies}
            setUserGender={setUserGender}
            setUserLastname={setUserLastname}
            setUserFirstname={setUserFirstname}
            setUserPhoneNumber={setUserPhoneNumber}
            setUserJobTitle={setUserJobTitle}
          />
        );
      case "Mot de passe":
        return (
          <PasswordForm
            {...commonProps}
            handleSubmit={handleSubmit}
            userPassword={userPassword}
            userConfirmPassword={userConfirmPassword}
            userCguAccepted={userCguAccepted}
            userAcquisition={userAcquisition}
            setUserPassword={setUserPassword}
            setUserConfirmPassword={setUserConfirmPassword}
            setUserCguAccepted={setUserCguAccepted}
            setUserAcquisition={setUserAcquisition}
            formSubmittedError={formSubmittedError}
            submittingForm={submittingForm}
          />
        );
      default:
        return null;
    }
  };

  /**
   * Permet de récupérer l'image correspondant à l'étape actuelle du flux d'inscription.
   * @param {number} activeStep
   */
  const getCurrentStepImage = (activeStep, labels) => {
    const activeLabel = labels[activeStep];
    switch (activeLabel) {
      case "Mail":
        return "/assets/images/gorille-porte.png";
      case "Information société":
        return "/assets/images/gorille-2.png";
      case "Logo":
        return "/assets/images/gorille-3.png";
      case "Information utilisateur":
        return "/assets/images/gorille-4.png";
      case "Mot de passe":
        return "/assets/images/gorille-5.png";
      default:
        return null;
    }
  };

  return !loading && (
      <>
        {shouldRedirect ? (
          <Redirect to="/" />
        ) : (
          <SignUpBody>
            <SignUpFormContainer>
              {renderCurrentForm(activeStep, stepperLabels)}
            </SignUpFormContainer>
            <GorillaImageContainer>
              <Image
                width="90%"
                src={getCurrentStepImage(activeStep, stepperLabels)}
                alt="gorilla entering"
              />
            </GorillaImageContainer>
            <WeedImageContainer />
          </SignUpBody>
        )}
      </>
    );
}

const SignUpBody = styled.div`
  width: 90%;
  margin: 0px 2% 0px 8%;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;

  @media screen and (max-width: 520px) {
    width: unset;
    display: block;
    margin: 0px 8% 0px;
  }
`;

const SignUpFormContainer = styled.div`
  position: relative;
  width: 40%;
  margin-left: 10%;
  margin-top: 5vw;
  text-align: left;
  z-index: 1;

  @media screen and (max-width: 520px) {
    width: unset;
    margin-left: 0;
  }
`;

const GorillaImageContainer = styled.div`
  position: relative;
  width: 40%;
  margin-right: 5%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1;

  @media screen and (max-width: 520px) {
    display: none;
  }
`;

const WeedImageContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 87vh;
  right: 0;
  bottom: 0;
  background-image: url("/assets/images/plante-rose.png");
  background-size: calc(15em + 7vw);
  background-repeat: no-repeat;
  background-position-x: right;
  z-index: 0;
`;
