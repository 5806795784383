import React from "react";
import styled from "styled-components";

import { BigTitle } from "../../shared/utils/styled-components-library.utils";

export default function LegalNotice() {
	return (
		<PageContainer>
			<BigTitle>MENTIONS LÉGALES</BigTitle>
			<CustomParagraph>
				<b>Raison sociale</b>: SVEA <br />
				<b>Forme juridique</b>: SAS <br />
				<b>Responsable</b>: Renuka FROMENTIN <br />
				<br />
				Conformément à la loi n°2004-575 du 21 juin 2004 pour la confiance dans
				l’économie numérique, nous vous informons que le site internet 1TERCO
				est la propriété de SVEA. Le site internet 1TERCO est hébergé sur les
				matériels informatiques de la société OVH dont le siège social est : 2
				rue Kellermann - 59100 Roubaix – France. Le site 1TERCO est régi par le
				droit français ; les visiteurs ayant accès au site de l’étranger,
				doivent s'assurer du respect des loi localement applicables.Les mentions
				légales pouvant être modifiées à tout moment et sans préavis, nous vous
				invitons à les consulter régulièrement.
			</CustomParagraph>
			<br />
			<ArticleHeader>Propriété intellectuelle </ArticleHeader>
			<CustomParagraph>
				1TERCO détient, se réserve et conserve tous les droits de propriété,
				notamment intellectuelle, y compris les droits de reproduction sur le
				présent site et les éléments qu’il contient. En conséquence et notamment
				toute reproduction p artielle ou totale du présent site et des éléments
				qu’il contient est strictement interdite sans autorisation écrite de
				SVEA. Les logos et tous autres signes distinctifs contenus sur ce site
				sont la propriété de SVEA ou font l'objet d'une autorisation
				d'utilisation. Aucun droit ou licence ne saurait être attribué sur l'un
				quelconque de ces éléments sans l'autorisation écrite de SVEA ou du
				tiers détenteur des droits.
			</CustomParagraph>
			<br />
			<ArticleHeader>
				Exclus ion de garantie et de responsabilité{" "}
			</ArticleHeader>
			<CustomParagraph>
				L'utilisateur du site web 1TERCO reconnaît avoir pris connaissance des
				présentes conditions d'utilisation du site et s'engage à les
				respecter. SVEA ne saurait être tenue pour responsable des dommages
				directs ouindirects qui pourraient résulter de l'accès au site ou de
				l'utilisation du site et/ou de ces informations, y compris
				l'inaccessibilité, l es pertes de données, détériorations, destructions
				ou virus qui pourraient affecter l'équipement informatique de
				l'utilisateur et/ou de la présence de virus sur son site. Bien que SVEA
				s'efforce de fournir un contenu fiable sur son site, il ne garantit pas
				que son contenu soit exempt d'inexactitudes ou d'omissions et ne saurait
				être tenu pour responsable des erreurs ou omission, d'une absence de
				disponibilité des informations et des services. SVEA se réserve à tout
				moment et sans préavis le droit d'apporter des améliorations et/ou des
				modifications au contenu de son site. En conséquence, l'utilisateur
				reconnaît se servir de ses informations sous sa responsabilité
				exclusive.
			</CustomParagraph>
			<br />
			<ArticleHeader>
				Vie privée - Protection des données personnelles{" "}
			</ArticleHeader>
			<CustomParagraph>
				Concernant les informations à caractère nominatif que vous seriez amenés
				à nous communiquer, vous bénéficiez d’un droit d’accès, de modification,
				de rectification et/ou de suppression conformément à la loi relative à
				la protection des personnes physiques à l’égard des traitements de
				données à caractère personnel n°2004-801 du 6 août 2004. Pour exercer ce
				droit veuillez adresser un message à : SVEA 50 Quai Louis Blériot 75016
				Dans le cadre de son activité, SVEA peut avoir besoin de transmettre les
				données collectées dans d’autres pays, pour l’une des finalités pour
				lesquelles les données ont été initialement collectées ou pour les
				objectifs auxquels les internautes ont consenti par la suite. Lorsque
				ces données sont transférées dans un pays tiers, SVEA prend les mesures
				nécessaires pour éviter tout préjudice. Les données peuvent être
				transférées vers des pays qui ne sont pas membres de l’Union Européenne.
				Les informations collectées font l'objet d'un traitement dans un fichier
				informatisé. Chaque internaute dispose d'un droit d'accès, de
				modification, de rectification et de suppression des données qui le
				concernent. (Art. 34 de la loi "Informatique et Libertés"). Ce droit
				peut être exercé auprès de : contact@1terco.com. Chaque utilisateur peut
				également adresser à SVEA des directives relatives au sort de ses
				données après son décès. La base légale du traitement est votre
				consentement à communiquer à SVEA vos informations à caractère
				nominatif. Ce fichier permet de : - Faciliter la mise en relation entre
				utilisateur du site 1terco - Partager des profils de candidats
				(intercontrat ou freelance) disponibles - Trouver de nouvelles missions
				pour des intercontrats ou freelance - Permettre de trouver des candidats
				(intercontrats ou freelance) pour des postes ou des appels d’offres à
				pourvoir. - Permettre d’être alerté par mail lorsqu’un candidat
				correspond aux critères de l’utilisateur - D’agrandir son réseau - De
				faire monter en compétences son personnel en intercontrat Catégories de
				données collectées : Information sur la société de l’utilisateur :
				localisation, effectif, secteur et siret - Identité : civilité, nom,
				prénom, numéro de téléphone, adresse électronique - CV ou dossier de
				compétences de l’intercontrat ou du freelance - Expériences et
				compétences professionnelles - Taux de facturation pour les prestations
				proposées - Destinataires des données : Seul 1terco pourra accéder au
				fichier de données collectées. Les utilisateurs pourront accéder aux
				expériences et compétences professionnelles des intercontrats ou
				freelance. S’il le souhaite, l’utilisateur peut rendre son profil non
				anonyme et ainsi rendre publiques les informations le concernant. Les
				données à caractère personnel collectées sur ce site sont conservées au
				maximum jusqu’à l’expiration des délais de prescription légaux. Usage
				personnel (commercial publicitaire interdite) : Les éléments de ce site
				Internet sont destinés uniquement à un usage personnel. Il est interdit
				d’utiliser les éléments de ce site à des fins commerciales ou
				publicitaires sans le consentement écrit de SVEA  Suspension de l’accès
				au site : SVEA peut suspendre l’accès au site par l’utilisateur sans
				préavis ni justification.
			</CustomParagraph>
			<br />
			<ArticleHeader>Cookies, TAGS et traceurs </ArticleHeader>
			<CustomParagraph>
				Lors de la navigation sur notre site internet, des informations
				relatives à votre navigation peuvent être enregistrées dans votre
				terminal (ordinateur, tablette, smartphone ...) au travers de fichiers
				appelés "Cookies". L es cookies nous permettent notamment de réaliser un
				suivi de la fréquentation de notre site Internet. Vous pouvez modifier
				vos préférences en matière de cookies en paramétrant votre navigateur :
				Safari Chrome Firefox Internet Explorer iOS
			</CustomParagraph>
			<br />
			<ArticleHeader>Liens vers d'autres sites </ArticleHeader>
			<CustomParagraph>
				Le site peut inclure des liens vers d'autres sites ou d'autres sources
				Internet. Dans la mesure où SVEA ne peut contrôler ces sites et ces
				sources externes, SV EA ne peut être tenu pour responsable de la mise à
				disposition de ces s ites et sources externes, et décline ainsi toute
				responsabilité quant aux contenus, publicités, produits, services ou
				tout autre matériel disponible sur ou à partir de ces sites ou sources
				externes. Ces liens sont proposés aux utilisateurs du site 1TERCO en
				tant que service. La décision d'activer les liens vous appartient
				exclusivement. Nous vous rappelons que les sites non-affiliés sont
				soumis à leurs propres conditions d'utilisation. Consultez le site
				cnil.fr pour plus d’informations sur vos droits. Si vous estimez, après
				nous avoir contactés, que vos droits « Informatique et Libertés » ne
				sont pas respectés, vous pouvez adresser une réclamation à la CNIL.
			</CustomParagraph>
		</PageContainer>
	);
}

const PageContainer = styled.div`
  margin-bottom: 200px;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
`;

const ArticleHeader = styled.h3`
  color: #241f47;
  font-size: 24px;
  font-family: "Prompt Semi Bold";
  text-align: left;
  font-weight: normal;
  margin-top: 1.5em;
`;

const CustomParagraph = styled.p`
  font-family: "Prompt Light";
  font-size: 18px;
  margin: 0;
  color: #333;
  line-height: 24px;
  text-align: ${props => (props.align ? props.align : "left")};
`;
