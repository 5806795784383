import React from "react";
import styled from "styled-components";

import { BigTitle } from "../../../shared/utils/styled-components-library.utils";
import Button from "../../../shared/components/buttons/button.component";
import TextInput from "../../../shared/components/inputs/text-input.component";

import { isValid } from "../../../shared/utils/input-validation.utils";

export default function EmailForm({ email, onChange, setUserProfile, handleNext, setIsJoiningCompany, firstOptionChosen, setFirstOptionChosen, setCompletedSteps }) {
	const goNext = (optionChosen, userProfile, joiningCompany) => {
		setUserProfile(userProfile);
		setIsJoiningCompany(joiningCompany);
		if (firstOptionChosen !== optionChosen) {
			setCompletedSteps({ 0: true });
		} else {
			setCompletedSteps(completedSteps => ({ ...completedSteps, 0: true }));
		}
		setFirstOptionChosen(optionChosen);
		handleNext();
	}

	return (
		<>
			<BigTitle>
				<div>INSCRIVEZ VOTRE TRIBU</div>
				<div>OU REJOIGNEZ-LÀ !</div>
			</BigTitle>
			<EmailFormContainer>
				<TextInput
					value={email}
					onChangeHandler={(e) => onChange(e.target.value)}
					label="Adresse e-mail"
					required
					disabled
				/>
				<EmailFormButtonsContainer>
					<Button
						onClick={() => goNext(0, "end-client", false)}
						disabled={!isValid('email', email)}
						secondary
						light
					>
						Je suis client final
				</Button>
					<Button
						onClick={() => goNext(1, "company", false)}
						disabled={!isValid('email', email)}
						secondary
						light
					>
						J'inscris ma société de conseil
				</Button>
					<Button
						onClick={() => goNext(2, "company", true)}
						disabled={!isValid('email', email)}
						secondary
						light
					>
						Je rejoins une société de conseil
				</Button>
					<Button
						onClick={() => goNext(3, "freelance", false)}
						disabled={!isValid('email', email)}
						secondary
						light
					>
						Je suis freelance
				</Button>
				</EmailFormButtonsContainer>
			</EmailFormContainer>
		</>
	)
};

const EmailFormContainer = styled.div`
	@media screen and (min-width: 520px) {
		width: 80%;
	}
`;

const EmailFormButtonsContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	& > * {
		margin: 8px 0px;
		width: 300px;
	}
`;