import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { CreateIntercoContext } from "../../contexts/create-interco.context";
import { AuthContext } from "../../contexts/auth.context";
import { BigTitle } from "../../shared/utils/styled-components-library.utils";
import Stepper from "../../shared/components/stepper.component";
import * as api from "../../shared/utils/api.utils";
import InfoForm from "../../specific/components/createinterco/info-form.component";
import SkillsForm from "../../specific/components/createinterco/skills-form.component";
import ExperiencesForm from "../../specific/components/createinterco/experiences-form.component";
import WishesForm from "../../specific/components/createinterco/wishes-form.component";
import PricesForm from "../../specific/components/createinterco/prices-form.component";
import DocumentsForm from "../../specific/components/createinterco/documents-form.component";
import { fetchAllFieldsByCategories } from '../../shared/utils/fetch.utils'; 

export default function CreateIntercoPage({
  isFreelance = false,
  openSignInModal,
}) {
  const history = useHistory();
  const [auth] = useContext(AuthContext);
  const [formData, ] = useContext(CreateIntercoContext);
  const [activeStep, setActiveStep] = useState(0);
  const [subStep, setSubStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState({});

  const [submittingInterco, setSubmittingInterco] = useState(false);
  const [submitAlert, setSubmitAlert] = useState({
    show: false,
    type: null,
    message: null,
  });
  const [fields, setFields] = useState([]);

  const stepperLabels = [
    `Informations${!isFreelance ? " intercontrat" : ""}`,
    "Compétences",
    "Expérience",
    "Souhaits",
    "Prix",
    "Documents",
  ];

  useEffect(() => {
    // on vérifie si l'utilisateur a accédé à la bonne page pour créer son profil/interco
    if (auth.profile === "freelance" && !isFreelance) {
      history.push("/creer-fiche-profil");
    } else if (auth.profile === "company" && isFreelance) {
      history.push("/creer-interco");
    } else {
      if (auth.logged) {
        // on vérifie, pour un freelance, s'il a le droit de créer une fiche profil
        if (auth.profile === "freelance") {
          const antiCachingData = new Date(); // ceci est nécessaire pour éviter que le navigateur garde en cache le résultat du premier call
          api
            .get(
              `/profiles/check-freelance/${
                auth.userId
              }?d=${antiCachingData.getTime()}`
            )
            .then((response) => {
              if (response && response.allowed === false) {
                history.push("/espace-personnel");
              }
            });
        }
      }
    }
  }, [auth, isFreelance, history]);

  useEffect(() => {
    fetchAllFieldsByCategories((_fields) => setFields(_fields));
  }, []);

  const handleNext = () => {
    setActiveStep((formerStep) => formerStep + 1);
    window.scrollTo(0, 0);
  };

  const handlePrevious = () => {
    setActiveStep((formerStep) => formerStep - 1);
    window.scrollTo(0, 0);
  };

  const handleStep = (step) => {
    setActiveStep(step);
    window.scrollTo(0, 0);
  };

  const handleSubmit = (documentFilename) => {
    if (submitAlert.type === "success" || submittingInterco) {
      return null;
    }

    setSubmittingInterco(true);
    const payload = {
      anonymous: formData.infoForm.anonymous,
      title: formData.infoForm.title.trim(),
      fieldIds: formData.infoForm.fieldIds,
      availability: formData.infoForm.availability,
      duration: formData.infoForm.duration,
      renewable: formData.infoForm.isRenewable,
      firstname: formData.infoForm.firstname.trim(),
      lastname: formData.infoForm.lastname.trim(),
      workLocations: formData.infoForm.workLocationsSelected,
      workFormat: formData.infoForm.workFormat,
      studyLevel: formData.infoForm.studyLevel,
      experienceYears: formData.infoForm.experienceYears,
      englishLevel: formData.infoForm.englishLevel,
      hasEmpowerment: formData.infoForm.hasEmpowerment,
      empowerments: formData.infoForm.empowerments,
      skills: formData.skillsForm.skills,
      experiences: formData.experiencesForm.experiences,
      wishes: formData.wishesForm.wish.trim(),
      trialPeriod: formData.pricesForm.preEmploymentOpen,
      hasRecommendation: formData.pricesForm.hasRecommendation,
      price: formData.pricesForm.price
        .replace(/ /g, "")
        .replace("€", "")
        .replace(",", "."),
      priceHidden: formData.pricesForm.priceHidden,
      fileName: documentFilename,
      userProfile: isFreelance ? "freelance" : "company",
    };

    const profileLabel = isFreelance ? "fiche profil" : "interco";
    api.post("/profiles", payload).then((response) => {
      if (response && response.success) {
        if (!!documentFilename) {
          // si l'utilisateur a entré un Dossier de compétences / CV, alors on parse le fichier
          api
            .post("/profiles/file-parsing", { profileId: response.profileId })
            .then((res) => {
              if (res && res.success) {
                // setSubmitAlert({ show: true, type: "success", message: `La création de votre ${profileLabel} a aboutie avec succès !` });
                localStorage.setItem("new-profile", true);
                window.location.href = "/espace-personnel";
              } else {
                setSubmitAlert({
                  show: true,
                  type: "error",
                  message:
                    res.errorMessage ||
                    `La création de votre ${profileLabel} n'a pas aboutie.`,
                });
                setSubmittingInterco(false);
              }
            });
        } else {
          // setSubmitAlert({ show: true, type: "success", message: `La création de votre ${profileLabel} a aboutie avec succès !` });
          localStorage.setItem("new-profile", true);
          window.location.href = `/espace-personnel?onglet=intercos&matching=${response.hash}`;
        }
      } else {
        setSubmitAlert({
          show: true,
          type: "error",
          message:
            response.errorMessage ||
            `La création de votre ${profileLabel} n'a pas aboutie.`,
        });
        setSubmittingInterco(false);
      }
    });
  };

  const renderCurrentForm = (currentStep) => {
    const commonProps = {
      handlePrevious,
      handleNext,
      activeStep,
      setCompletedSteps,
      isFreelance,
      context: CreateIntercoContext,
    };
    switch (currentStep) {
      case 0:
        return (
          <InfoForm
            {...commonProps}
            subStep={subStep}
            setSubStep={setSubStep}
            openSignInModal={openSignInModal}
            fields={fields}
          />
        );
      case 1:
        return (
          <SkillsForm {...commonProps} />
        );
      case 2:
        return <ExperiencesForm {...commonProps} />;
      case 3:
        return <WishesForm {...commonProps} />;
      case 4:
        return <PricesForm {...commonProps} />;
      case 5:
        return (
          <DocumentsForm
            {...commonProps}
            handleSubmit={handleSubmit}
            submittingInterco={submittingInterco}
            submitAlert={submitAlert}
          />
        );
      default:
        return null;
    }
  };

  return (
    <PageContainer>
      <BigTitle>
        PROPOSEZ VOTRE {isFreelance ? "PROFIL" : "INTERCO"} AUX AUTRES TRIBUS
      </BigTitle>
      <StepperContainer>
        <Stepper
          style={{ backgroundColor: "transparent" }}
          labels={stepperLabels}
          activeStep={activeStep}
          handleStep={handleStep}
          completedSteps={completedSteps}
        />
      </StepperContainer>
      {renderCurrentForm(activeStep)}
    </PageContainer>
  );
}

const PageContainer = styled.div`
  padding: 40px 5% 0 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StepperContainer = styled.div`
  width: 100%;
`;
