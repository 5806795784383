import { useState, useEffect } from "react";
import { post } from "../../../shared/utils/api.utils";
import { CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { get, deleteReq } from "../../../shared/utils/api.utils";
import Button from "../../../shared/components/buttons/button.component";
import ConfirmModal from "../../../shared/modals/confirm-modal";
import moment from "moment";
import { Link } from "react-router-dom";

export default function Payment({ user }) {
  const [alertTop, setAlertTop] = useState({
    show: false,
    type: null,
    message: "",
  });
  const [shouldCheckPayment, setShouldCheckPayment] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [canCancel, setCanCancel] = useState(false);
  const [canSubscribe, setCanSubscribe] = useState(false);

  const createCheckout = () => {
    if (user?.type === "admin") {
      setLoading(true);
      post("/stripe/create-checkout-session")
        .then((response) => {
          if (response.url) window.location.href = response.url;
        })
        .catch((err) => {
          setAlertTop({
            show: true,
            type: "error",
            message:
              "Erreur lors de la création de la session de paiement : " +
              err?.message,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const deleteSubscription = () => {
    if (user?.type === "admin") {
      setLoading(true);
      deleteReq("/stripe")
        .then(() => {
          setShouldCheckPayment(true);
        })
        .catch((err) => {
          setAlertTop({
            show: true,
            type: "error",
            message:
              "Erreur lors de la suppression de l'abonnement : " + err?.message,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!shouldCheckPayment) return;
    setLoading(true);
    get("/stripe/has-paid")
      .then((res) => {
        if (res.hasPaid) {
          console.log(res);
          const date = moment(res.endDate).format("DD/MM/YY - HH[h]mm");
          console.log("active", new Date(res.endDate) > new Date());
          if (new Date(res.endDate) > new Date()) {
            console.log("helo", res.stripeOngoing);
            setAlertTop({
              show: true,
              type: res.stripeOngoing === 1 ? "success" : "warning",
              message:
                res.stripeOngoing === 1
                  ? `Votre société est abonnée à la plateforme jusqu'au ${date}. Le renouvellement est automatique.`
                  : `Votre abonnement arrivera à échéance le ${date}. Il prendra fin et vous pourrez souscrire à un nouvel abonnement sur cette page`,
            });
            setCanCancel(res.stripeOngoing === 1);
            setCanSubscribe(false);
          } else {
            setAlertTop({
              show: true,
              type: "warning",
              message: `Votre société n'est pas abonnée à la plateforme.${
                user.type === "user" ? " Contactez votre administrateur." : ""
              }`,
            });
            setCanCancel(false);
            setCanSubscribe(true);
          }
        } else {
          setAlertTop({
            show: true,
            type: "warning",
            message: `Votre société n'est pas abonnée à la plateforme.${
              user.type === "user" ? " Contactez votre administrateur." : ""
            }`,
          });
          setCanCancel(false);
          setCanSubscribe(true);
        }
      })
      .finally(() => {
        setLoading(false);
        setShouldCheckPayment(false);
      });
  }, [shouldCheckPayment]);

  return loading ? (
    <CircularProgress />
  ) : (
    <>
      {alertTop.show && (
        <Alert
          style={{ marginBottom: "2em" }}
          severity={alertTop.type}
          onClose={() => {
            setAlertTop({ show: false, type: null, message: null });
          }}
        >
          {alertTop.message}
        </Alert>
      )}
      {canCancel && user.type === "admin" && (
        <Button onClick={() => setShowConfirmModal(true)}>
          Annuler l'abonnement
        </Button>
      )}
      {canSubscribe && user.type === "admin" && (
        <div>
          <div>Pour vous abonner à la plateforme, veuillez nous contacter</div>
          <Link to="/nous-contacter">
            <Button>Nous contacter</Button>
          </Link>
        </div>
      )}

      <ConfirmModal
        onConfirm={deleteSubscription}
        onCancel={() => setShowConfirmModal(false)}
        showModal={showConfirmModal}
        message="Êtes-vous sûr de vouloir annuler votre abonnement ? Tous vos profils et besoins seront désactivés. Toutes leurs candidatures seront perdues."
        closeModal={() => setShowConfirmModal(false)}
      />
    </>
  );
}
