import moment from "moment";
import { post } from "./api.utils";

/**
 * Fonction permettant d'obtenir les profiles.
 * @param {object} params
 */
export const getProfiles = async (search = null) => {
  return await post("/profiles/get-elastic-profiles", { search });
};

export const minDate = (profile) => {
	if (profile.availability === 'ASAP') {
		return moment().format("DD/MM/YYYY");
	}
	if (profile.availability === 'Supérieur à 6 mois') {
		return 'Dans plus de 6 mois';
	}
	let threshold = moment(new Date(profile.updatedAt.split(' ')[0])).endOf("month");
	let availability = parseInt(profile.availability - 1);
	threshold.add(availability, "months");
	return threshold.format("DD/MM/YYYY");
};

/**
 * Fonction permettant d'appliquer les filtres sélectionnés par l'utilisateur.
 * @param {object[]} profiles
 * @param {object} filters
 */
export const applyFilters = (profiles, filters) => {
  let filteredProfiles = profiles.map((profile) => ({ ...profile }));
  filteredProfiles = filteredProfiles.map((profile) => {
      let threshold = moment(new Date(profile.updatedAt)).endOf("month");
      let availability = parseInt(profile.availability - 1);
      threshold.add(availability, "months");
      let delta = moment(threshold).diff(moment(), "months");
      profile.delta = delta;
      profile.minDate = threshold.format("DD/MM/YYYY");
      return profile;
    });

  const allowsRemote = !!filters?.workFormat.length;

  //   Secteurs d'activité
  if (!!filters?.fields.length) {
    const fieldIdsSelected = filters.fields.map((item) => item.fieldId);
    filteredProfiles = filteredProfiles.filter((profile) => {
      const profileFieldIds = profile.fieldIds?.map((field) => field.fieldId);
      return profileFieldIds?.some((fieldId) =>
        fieldIdsSelected.includes(fieldId)
      );
    });
  }

  // Type
  if (!!filters?.profileType.length) {
    const valuesSelected = filters.profileType.map((option) => {
      if (option.label === 'ESN')
        return 'company';
      if (option.label === 'Client final')
        return 'end-client';
      return 'freelance';
    });
    filteredProfiles = filteredProfiles.filter((profile) => {
			if (profile.userProfile){  // in the case of a profile
				if (profile.userProfile === "company" && valuesSelected.includes(1))
					return true;
				if (profile.userProfile === "freelance" && valuesSelected.includes(2))
					return true;
				return false;
			} else { // in the case of a need
				if (valuesSelected.includes(profile.profile)) {
					return true;
        }
				return false;
			}
    });
  }

  // Mobilité
  if (!!filters?.workLocations.length) {
    filteredProfiles = filteredProfiles.filter((profile) => {
      let isIncluded = false;
      for (const location of profile.workLocations) {
        const isDepartment = location.includes(".");
        if (
          isDepartment &&
          filters.workLocations.includes(location.split(".")[0])
        )
          isIncluded = true;
        if (filters.workLocations.includes(location)) isIncluded = true;
        if (location === "1") isIncluded = true;
      }
      if (profile)
        if (
          allowsRemote &&
          (profile.workFormat === 2 || profile.workFormat === 3)
        )
          // Télétravail (avec mobilité)
          isIncluded = true;
      return isIncluded;
    });
  }

  // Télétravail (sans mobilité)
  if (allowsRemote && !filters.workLocations?.length) {
    filteredProfiles = filteredProfiles.filter(
      (profile) => profile.workFormat !== 1
    );
  }

  // Niveau d'études
  if (!!filters.studyLevel?.length) {
    const valuesSelected = filters.studyLevel.map((option) => option.value);
    filteredProfiles = filteredProfiles.filter((profile) => {
      if (valuesSelected.includes(1) && profile.studyLevel === 1) return true;
      if (valuesSelected.includes(2) && profile.studyLevel === 2) return true;
      if (valuesSelected.includes(3) && profile.studyLevel === 3) return true;
      if (valuesSelected.includes(4) && profile.studyLevel === 4) return true;
      if (valuesSelected.includes(5) && profile.studyLevel === 5) return true;
      if (valuesSelected.includes(6) && profile.studyLevel === 6) return true;
      return false;
    });
  }

  // Niveau d'anglais
  if (!!filters.englishLevel?.length) {
    const valuesSelected = filters.englishLevel.map((option) => option.value);
    filteredProfiles = filteredProfiles.filter((profile) =>
      valuesSelected.includes(profile.englishLevel)
    );
  }

  // Expérience
  if (!!filters.experienceYears?.length) {
    const valuesSelected = filters.experienceYears.map(
      (option) => option.value
    );
    filteredProfiles = filteredProfiles.filter((profile) => {
      if (
        valuesSelected.includes(1) &&
        [1, 2].includes(profile.experienceYears)
      )
        return true;
      if (
        valuesSelected.includes(2) &&
        [3, 4, 5].includes(profile.experienceYears)
      )
        return true;
      if (
        valuesSelected.includes(3) &&
        [6, 7, 8, 9].includes(profile.experienceYears)
      )
        return true;
      if (
        valuesSelected.includes(4) &&
        [10, 11].includes(profile.experienceYears)
      )
        return true;
      return false;
    });
  }

  // Disponibilité
  if (!!filters.availability?.length) {
    const valuesSelected = filters.availability.map((option) => option.value);
    console.log(valuesSelected);
    filteredProfiles = filteredProfiles.filter((profile) => {
      if (valuesSelected.includes(1) && profile.delta <= 0) return true;
      if (valuesSelected.includes(2) && [1, 2].includes(profile.delta))
        return true;
      if (valuesSelected.includes(3) && [3, 4, 5].includes(profile.delta))
        return true;
      if (valuesSelected.includes(4) && profile.delta >= 6) return true;
      return false;
    });
  }

  // Tjm
  if (filters.tjm !== undefined) {
    filteredProfiles = filteredProfiles.filter(
      (profile) =>
        profile.priceHidden ||
        (profile.price >= filters.tjm[0] && profile.price <= filters.tjm[1])
    );
  }

  return filteredProfiles;
};

/**
 * Fonction permettant de récupérer la liste des labels des régions/départements d'un profil.
 * @param {object[]} workLocations
 * @param {string[]} ids
 */
export const getLocationLabelsById = (workLocations, ids) => {
  if (!workLocations.length) return [];
  const labels = [];
  for (const id of ids) {
    labels.push(getLocationLabelById(workLocations, id));
  }
  return labels;
};

/**
 * Fonction permettant de récupérer le nom de la région/du département via son id.
 * @param {string} id Correspond à un id de région OU de région + "." + département.
 */
export const getLocationLabelById = (workLocations, id) => {
  if (!workLocations.length) return "";
  const isDepartment = id.includes(".");
  if (isDepartment) {
    const locationArray = id.split(".");
    const region = workLocations.find(
      (region) => region.value === locationArray[0]
    );
    const department = region.children.find(
      (_department) => _department.value.split(".")[1] === locationArray[1]
    );
    return department.title;
  } else {
    const region = workLocations.find((region) => region.value === id);
    return region.title;
  }
};
