import React from "react";
import styled from "styled-components";

import { BigTitle } from "../../shared/utils/styled-components-library.utils";
import Image from "../../shared/components/images/image.component";

export default function ContactUs() {

	return (
		<PageContainer>
			<PageHeader>
				<BigTitle>À PROPOS</BigTitle>
				<Description align="center">
					1terco est né en 2020, une année qui fut particulièrement douloureuse pour les sociétés de services suite aux conséquences de l’épidémie du Coronavirus. En effet, de nombreuses sociétés ont vu les missions de leurs consultants s’interrompre et de ce fait, se sont retrouvées avec un nombre important d’intercontrats.
				</Description>
			</PageHeader>
			<Image src="/assets/images/about-top.png" width="100%"/>
			<ArticlesWrapper>
				<ArticlesContainer>
					<Article>
						<ArticleContent>
							<ArticleBody>
								<ArticleText>
									<ArticleHeader>La force du réseau</ArticleHeader>
									<CustomParagraph>
										<span>L’idée du site est venue d’un Ingénieur d’Affaires se retrouvant précisément dans ce cas. Face à cette situation, n’arrivant pas à retrouver des missions pour ses consultants chez ses clients, il s’est tourné vers son réseau en diffusant les dossiers de ses inter-contrats.</span><br />
										<span>Son objectif était clairement de solliciter ses confrères et partenaires, il savait qu’il avait plus de chance de repositionner ses consultants. Sentant qu’il y avait un réel besoin, il a donc imaginé une interface de mise en relation entre ESN, 1terco.com.</span><br />
										<span>À l’heure où nous faisons face à une concurrence très forte sur le secteur, la solution pour exister est de nouer des partenariats intelligents, des relations win-win avec les confrères, et ainsi de multiplier des opportunités.</span>
									</CustomParagraph>
								</ArticleText>
								<ArticleImageContainer>
									<Image src="/assets/images/gorille-rencontre.png" width="100%"/>
								</ArticleImageContainer>
							</ArticleBody>
						</ArticleContent>
					</Article>
					<Article>
						<ArticleContent>
							<ArticleBody>
								<ArticleImageContainer>
									<Image src="/assets/images/gorille-5.png" width="70%"/>
								</ArticleImageContainer>
								<ArticleText>
									<ArticleHeader>L’outil indispensable des ESN</ArticleHeader>
									<CustomParagraph>
										<span>1terco a pour ambition d’être l’outil indispensable des ESN et la référence sur le marché.</span><br/>
										<span>Le site a été conçu pour etre clair, simple, pragmatique et efficace!</span><br/>
										<span>L’interface a été pensée pour les professionels du secteur et le site est en constante évolution, de nouvelles fonctions sont rajoutées afin de faciliter le travail des Ingénieurs d’Affaires. Nous savons tous que le process de recrutement des candidats est long, grâce à 1terco, accédez à un vivier de candidats toujours en poste, fiables et disponibles prochainement.</span>
									</CustomParagraph>
								</ArticleText>
							</ArticleBody>
						</ArticleContent>
					</Article>
				</ArticlesContainer>
			</ArticlesWrapper>
		</PageContainer>
	);
}

const PageContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: -15em;
`;

const PageHeader = styled.div`
	width: 60%;
	margin-bottom: -8vw;

	& > * {
		margin-bottom: 40px;
	}
`;

const ArticlesWrapper = styled.div`
	padding-bottom: 20em;
	background-image: linear-gradient(to right, #f9cdd5 20%, #f29daa 105%);
`;
const ArticlesContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 80%;
	margin: 5em auto 0 auto;
`;

const Article = styled.div`
	background-color: white;
	border-radius: 40px;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
	display: flex;
	margin: 20px 0px;
	flex-direction: ${props => props.reversed ? "row-reverse" : "row"};

	& > * {
		margin: 0 1%;
	}
`;

const ArticleContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: calc(2em + 2vw) 2vw;
`;

const ArticleHeader = styled.h3`
	color: #241f47;
	font-size: 26px;
	font-family: "Prompt Black";
	text-transform: uppercase;
	text-align: left;
	font-weight: normal;
	margin-bottom: 20px;
`;

const ArticleBody = styled.div`
	display: flex;
`;

const ArticleText = styled.div`
	flex: 4;
	padding: 0 2em;
`;

const ArticleImageContainer = styled.div`
	flex: 5;
	display: flex;
	align-items: center;
	justify-content: center;

	@media screen and (max-width: 1050px) {
		display: none;
	}
`;

const Description = styled.p`
	font-family: "Prompt Light";
	font-size: 20px;
	margin: 0 auto;
	color: #241f47;
	line-height: 24px;
	text-align: ${props => props.align ? props.align : "left"};
	max-width: 650px;
`;

const CustomParagraph = styled.p`
	font-family: "Prompt Light";
	font-size: 16px;
	margin: 0;
	color: #241f47;
	line-height: 24px;
	text-align: ${props => props.align ? props.align : "left"};
`;